import axios from 'axios'
import cache from '@/plugins/cache'
import { getToken } from '@/utils/auth'
import { tansParams } from '@/utils/common'
import errorCode from '@/utils/errorCode'
import useUserStore from '@/store/modules/user'
import { ElNotification , ElMessageBox, ElMessage, ElLoading } from 'element-plus'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

// 是否显示重新登录
export let isRelogin = { show: false };

const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: process.env.VUE_APP_BASE_API,
    // 超时
    timeout: 10000
})

// 请求拦截器
service.interceptors.request.use(config => {
	// 是否需要设置 token
	const isToken = (config.headers || {}).isToken === false
	// 是否需要防止数据重复提交
	const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
	if (getToken() && !isToken) {
	  config.headers['accessToken'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
	}
	const isGmApi = (config.headers || {}).isGmApi === true//gm网站接口域名
	if(isGmApi){
	  config.baseURL = process.env.VUE_APP_GM_API;
	}
	const isTestApi = (config.headers || {}).isTestApi === true//gm网站接口域名
	if(isTestApi){
	  config.baseURL = process.env.VUE_APP_TEST_API;
	}
	const isIaaApi = (config.headers || {}).isIaaApi === true//iaa广告接口域名
	if(isIaaApi){
	  config.baseURL = process.env.VUE_APP_IAA_API;
	}
	// get请求映射params参数
	if (config.method === 'get' && config.params) {
	  let url = config.url + '?' + tansParams(config.params);
	  url = url.slice(0, -1);
	  config.params = {};
	  config.url = url;
	}
	// get请求映射params参数
	if (config.method === 'get' && config.params) {
	  let url = config.url + '?';
	  for (const propName of Object.keys(config.params)) {
	    const value = config.params[propName];
	    var part = encodeURIComponent(propName) + "=";
	    if (value !== null && typeof(value) !== "undefined") {
	      if (typeof value === 'object') {
	        for (const key of Object.keys(value)) {
	          if (value[key] !== null && typeof (value[key]) !== 'undefined') {
	            let params = propName + '[' + key + ']';
	            let subPart = encodeURIComponent(params) + '=';
	            url += subPart + encodeURIComponent(value[key]) + '&';
	          }
	        }
	      } else {
	        url += part + encodeURIComponent(value) + "&";
	      }
	    }
	  }
	  url = url.slice(0, -1);
	  config.params = {};
	  config.url = url;
	}
	// if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
	//   const requestObj = {
	// 	url: config.url,
	// 	data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
	// 	time: new Date().getTime()
	//   }
	//   const sessionObj = cache.session.getJSON('sessionObj')
	//   if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
	// 	cache.session.setJSON('sessionObj', requestObj)
	//   } else {
	// 	const s_url = sessionObj.url;                // 请求地址
	// 	const s_data = sessionObj.data;              // 请求数据
	// 	const s_time = sessionObj.time;              // 请求时间
	// 	const interval = 1000;                       // 间隔时间(ms)，小于此时间视为重复提交
	// 	if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
	// 	  const message = '数据正在处理，请勿重复提交';
	// 	  console.warn(`[${s_url}]: ` + message)
	// 	  return Promise.reject(new Error(message))
	// 	} else {
	// 	  cache.session.setJSON('sessionObj', requestObj)
	// 	}
	//   }
	// }
	delete config.headers.isToken
	return config
},
error => {
	console.log(error);
	return Promise.reject(error);
}
)

// 响应拦截器
service.interceptors.response.use(
    response => {
		console.log(response)
		// 未设置状态码则默认成功状态
		const code = response.data.code || 200;
		// 获取错误信息
		const msg = errorCode[code] || response.data.msg || errorCode['default']
		if(response.config.baseURL === process.env.VUE_APP_IAA_API){
			return Promise.resolve(response.data)
		}else{
			if (code !== 200) {
			   if(code == 401){
				   useUserStore().logOut().then(() => {
					   location.reload()
				   })
			   }else if(code == 500 && msg == '请登录'){
				   useUserStore().logOut().then(() => {
					   location.reload()
				   })
			   }else{
				   ElNotification.error({
					 title: 'ERROR '+code,
					 message: response.data.msg ? response.data.msg : msg
				   })
			   }
				return Promise.reject('error')
			}else {
				return Promise.resolve(response.data)
		   }
		}
    },
    error => {
        console.log('err' + error)
        let { message } = error;
        if (message == "Network Error") {
          message = "后端接口连接异常";
        }
        else if (message.includes("timeout")) {
          message = "系统接口请求超时";
        }
        else if (message.includes("Request failed with status code")) {
          message = "系统接口" + message.substr(message.length - 3) + "异常";
        }
        ElMessage({
          message: message,
          type: 'error',
          duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)
export default service;
