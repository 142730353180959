import cache from '@/plugins/cache'
import { defineStore } from 'pinia'
// import { login, register, getinfo } from '@/api/user'
import { getToken, setToken, removeToken, getName, setName, removeName, getOpenid, setOpenid, removeOpenid, setUserid, getUserid, removeUserid } from '@/utils/auth'

const useUserStore = defineStore(
  'user',
  {
    state: () => ({
      token: getToken(),
      // openids: this.getOpenIds(),
	  name: getName(),
	  userid: getUserid(),
      wcode: cache.session.get('pUser-code') ? cache.session.get('pUser-code') : '',
      gameid: cache.session.get('pGame-Id') ? parseInt(cache.session.get('pGame-Id')) : '',
	  userInfo: cache.session.getJSON('pUser-Info') ? cache.session.getJSON('pUser-Info') : '',
	  roleInfo: cache.session.getJSON('pRole-Info') ? cache.session.getJSON('pRole-Info') : '',
	  points: cache.session.get('pUser-points') ? cache.session.get('pUser-points') : '',
	  coupons: cache.session.get('pUser-coupons') ? cache.session.get('pUser-coupons') : '',
    }),
    actions: {
		getOpenIds(){
			let vals = getOpenid(),valObj = [];
			if(vals && vals.indexOf(',') > 0){
				valObj = JSON.parse(vals)
			}
			return valObj
		},
		clearCode(){
			cache.session.remove('pUser-code');
		},
		setOpenId(val,gameId){
			return new Promise((resolve, reject) => {
				let vals = getOpenid(),valObj = [];
				if(vals && vals.indexOf(',') > 0){
					valObj = JSON.parse(vals)
					for (const [i, v] of valObj.entries()) {
					  if (v.gameId === gameId) {
					    valObj.splice(i, 1)
					  }
					}
					valObj.push({ gameId, openId: val })
					setOpenid(JSON.stringify(valObj))
				}else{
					valObj.push({ gameId, openId: val })
					setOpenid(JSON.stringify(valObj))
				}
				resolve()
			})
		},
		setCode(val){
			return new Promise((resolve, reject) => {
				this.wcode = val
				cache.session.set('pUser-code', val);
				resolve()
			})
		},
		setActiveGame(id){
			return new Promise((resolve, reject) => {
				this.gameid = id
				cache.session.set('pGame-Id', id);
				resolve(id)
			})
		},
		setPoints(val){
			return new Promise((resolve, reject) => {
				this.points = val
				cache.session.set('pUser-points', val);
				resolve()
			})
		},
		setCoupons(val){
			return new Promise((resolve, reject) => {
				this.coupons = val
				cache.session.set('pUser-coupons', val);
				resolve()
			})
		},
	  setInfo(userInfo){
		  return new Promise((resolve, reject) => {
			setToken(userInfo.accessToken)
			setName(userInfo.userName)
			this.token = userInfo.accessToken
			this.name = userInfo.userName
			this.userid = userInfo.userId
			this.userInfo = userInfo
			setUserid(userInfo.userId)
			// cache.session.set('pUser-Id', userInfo.userId);
			cache.session.setJSON('pUser-Info', userInfo);
			resolve(userInfo)
		  })
	  },
	  setRoleInfo(roleInfo){
		  let arr = []
		  if(this.roleInfo){
			  arr = this.roleInfo
		  }
		  let index = arr.findIndex((item) => item.gameId == roleInfo.gameId)
		  if(index >= 0){
			  arr.splice(index, 1)
		  }
		  return new Promise((resolve, reject) => {
				arr.push(roleInfo)
				cache.session.setJSON('pRole-Info', arr);
				this.roleInfo = arr
				resolve(roleInfo)
		  })
	  },
    
      // 退出系统
      logOut() {
        return new Promise((resolve, reject) => {
			this.token = ''
			this.name = ''
			this.userid = ''
			this.userInfo = ''
			this.gameid = ''
			this.wcode = ''
			this.points = ''
			this.coupons = ''
			cache.session.remove('pRole-Info');
			cache.session.remove('pUser-Info');
			cache.session.remove('pGame-Id');
			cache.session.remove('pUser-code');
			cache.session.remove('pUser-points');
			cache.session.remove('pUser-coupons');
			removeUserid()
			removeName()
			removeToken()
			removeOpenid()
			resolve()
        })
      }
    }
  })
  
export default useUserStore
