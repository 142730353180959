<template>
	<div style="width: 100%;height: 100%;background: rgba(0,0,0,0.6);">
	<div class="popupBox">
		<div class="popupCon superGift" v-if="activeType == 'superGift'">
			<div class="conBox">
				<div class="title">观看广告领取超值福利</div>
				<template v-if="infos.totalNums">
					<div class="cons" v-if="infos.totalNums == infos.receiveNums">
						<p class="des"></p>
						<p class="nums" style="font-size: 30px;">今日红包<br>领取完毕</p>
					</div>
					<div class="cons" v-else>
						<p class="des">游戏内代币可用于兑换礼包</p>
						<div class="icons" v-if="props.icon">
							<div class="props"><img :src="props.icon"><span>{{ props.amount }}</span></div>
							<img class="simg" src="/iaa/popupCloses.png">
							<span>{{ infos.propNum || 1 }}</span>
						</div>
						<p class="nums">今日可领取次数：{{ Number(infos.totalNums) - Number(infos.receiveNums) }}</p>
					</div>
				</template>
				<div class="cons" v-else>
					<p class="des"></p>
					<p class="nums">{{ errMsg }}</p>
				</div>
				<div class="btns">
					<div class="ads" @click="showAdRules = true"><img src="/iaa/popupIcon.png"><span>{{ infos.cardNums || 0 }}/1</span></div>
					<div class="btnGet" :class="{'disabled': getLoading == true}" @click="handleCommit">领取红包</div>
					<p class="timer" v-if="activeType == 'superGift' &&Number(infos.coolDownTimer) > 0">
						<el-countdown title="" :value="cDownTimer" />
					</p>
				</div>
			</div>
		</div>
		<div class="popupCon rewardGift" v-if="activeType == 'rewardGift'">
			<div class="conBox">
				<div class="title">观看视频抽极品</div>
				<div class="rulesBox" @click="showRewardRules = true">
					<img src="/iaa/popupRuleIcon.png" />
					<span>概率说明</span>
				</div>
				<div class="props">
					<ul>
						<li v-for="(item,index) in rewardProps" :key="index">
							<img :src="item.icon" />
							<span>{{ item.amount }}</span>
						</li>
					</ul>
				</div>
				<div class="btns">
					<div class="items">
						<p><i class="key"></i><span>{{ rewardInfos.turntablePropNum || 0 }}/1</span></p>
						<div class="btnDraw" @click="handleDraw(1)">抽1次</div>
					</div>
					<div class="items">
						<p><i class="key"></i><span>{{ rewardInfos.turntablePropNum || 0 }}/10</span></p>
						<div class="btnDraw bg10" @click="handleDraw(10)">抽10次</div>
					</div>
				</div>
				<div class="keysList">
					<ul>
						<li v-for="(item,index) in keyList" :key="index">
							<div class="items" :class="{'active': item.isGet == 1}" @click="handleGetKeys(item)">
								<img v-if="item.isGet == 1 || item.isOpen == 0" src="/iaa/popupKeyBorderGray.png" />
								<img v-else src="/iaa/popupKeyBorder.png" />
								<span>{{ item.amount }}</span>
							</div>
							<p v-if="item.isOpen == 0 && index < 2">观看{{ item.num }}次解锁</p>
							<p v-else-if="item.isOpen == 1 && item.isGet == 0 && index < 2">观看{{ item.num }}次</p>
							<p v-else-if="index >= 2 && formatStatus() < 2">观看2次后解锁</p>
							<p v-else-if="item.isOpen == 0 && index >= 2">关注公众号解锁</p>
							<p v-else-if="item.isOpen == 1 && index >= 2">关注公众号</p>
							<p v-else-if="item.isGet == 1">明日再领</p>
						</li>
					</ul>
				</div>
				<div class="btns">
					<div class="items">
						<div class="btnDraw videos" :class="{'disabled':getKeyLoading == true}" @click="handleAdKeys">
							<template v-if="formatStatus() < 2"><i class="video"></i>获取钥匙</template>
							<template v-else>前往关注</template>
						</div>
						<p class="timer" v-if="activeType == 'rewardGift' && Number(rewardInfos.coolDownTime) > 0">
							<el-countdown title="" :value="cDownTime" />
						</p>
					</div>
					
				</div>
				<!-- <p class="rules">当日累计领取红包达到次数可领取对应奖励，次日奖励重置</p> -->
			</div>
		</div>
		<div class="menuBtns">
			<ul>
				<li @click="handleTypes('superGift')" :class="{'active':activeType == 'superGift'}">超值豪礼</li>
				<li v-if="infos.turntableOpen === '1'" @click="handleTypes('rewardGift')" :class="{'active':activeType == 'rewardGift'}">肝帝福利</li>
				<!-- turntableOpen  1才开 0不开 -->
			</ul>
		</div>
		<div class="closeBtn" @click="handleClose"></div>
		
		<Addialog :showVisible="showAdRules" @cancel="cancel">
			<div class="rulesPop" v-html="infos.describe"></div>
		</Addialog>
		
		<Addialog :showVisible="showAdReward" @cancel="cancel">
			<div class="rewardPop">
				<div class="props" :style="{ justifyContent:rewardsList.length>4?'flex-start':'center'}">
					<div class="items" v-for="(item,index) in rewardsList" :key="index">
						<img :src="item.icon" />
						<span class="nums">{{ item.amount }}</span>
					</div>
				</div>
				<p>恭喜获得</p>
			</div>
		</Addialog>
		
		<Addialog :showVisible="showRewardRules" @cancel="cancel">
			<div class="rulesPop" v-html="formatBr(rewardInfos.activeRule)"></div>
		</Addialog>
		
		<Addemo :showVisible="showRewardGzh" @cancel="cancel">
			<div class="gzhPop"><img src="~@/assets/images/demo1.png" /></div>
		</Addemo>
	</div>
</div>
</template>

<script setup>
	import { getCurrentInstance,onMounted,onBeforeMount,ref,watch,computed, reactive } from 'vue'
	import { ElMessage, ElMessageBox, ElLoading } from 'element-plus'
	import { useRoute,useRouter } from 'vue-router'
	import useIaaStore from '@/store/modules/iaaParams'
	import iaaSdk from '@/utils/iaa_sdk'
	import Addialog from '@/views/iaa/addialog'
	import Addemo from '@/views/iaa/addemo'
	
	const { proxy } = getCurrentInstance();
	const router = useRouter();
	const activeType = ref('superGift')
	const infos = ref({})
	const rewardInfos = ref({})
	const props = ref({})
	const rewardProps = ref([])
	const keyList = ref([])
	const cDownTimer = ref(Date.now())
	const cDownTime = ref(Date.now())
	const cTimer = ref(null)
	const cTime = ref(null)
	const cTimerNum = ref(0)
	const cTimeNum = ref(0)
	const getLoading = ref(false)
	const getKeyLoading = ref(false)
	const errMsg = ref('')
	const showAdRules = ref(false)
	const showAdReward = ref(false)
	const showRewardRules = ref(false)
	const showRewardGzh = ref(false)
	const rewardsList = ref([])
	
	const secretParams = computed(() => {
		return useIaaStore().secretParams
	})
	onMounted(() => {
		let query = router.currentRoute.value.query
		if(query && query.isTest){
			useIaaStore().setIsTest(query.isTest)
		}else{
			useIaaStore().setIsTest(0)
		}
		
		if(secretParams.value){
			getInfo()
		}else{
			init()
		}
		
	})
	const formatBr = (str) => {
		if(str != null){
		  str = str.replace(/\n/g,'<br/>');
		}
		return str;
	}
	const formatStatus = () => {
		let status = 0
		keyList.value.forEach((item,i) => {
			if(item.isGet == 1){
				status++
			}
		})
		return status
	}
	const init = () => {
		iaaSdk.sdkPostMsg({ action: 'webDidLoad' })
	}
	const ctimer = () => {
		getLoading.value = true
		cTimer.value = setInterval(() => {
			cTimerNum.value --
			// console.log(cTimerNum.value)
			if(cTimerNum.value < 0){
				clearInterval(cTimer.value)
				cTimer.value = null
				getLoading.value = false
				getInfo()
			}
		},1000)
	}
	const ctime = () => {
		getKeyLoading.value = true
		cTime.value = setInterval(() => {
			cTimeNum.value --
			// console.log(cTimeNum.value)
			if(cTimeNum.value < 0){
				clearInterval(cTime.value)
				cTime.value = null
				getKeyLoading.value = false
				getRewardInfo()
			}
		},1000)
	}
	const getInfo = () => {
		iaaSdk.sdkDialogInfo((data) => {
			infos.value = Object.assign({},data)//totalNums可领取总数量,receiveNums已领取的数量
			props.value = JSON.parse(data.items)[0]//默认一个
			if(Number(data.coolDownTimer) > 0){
				cDownTimer.value = Date.now() + 1000 * Number(data.coolDownTimer)
				cTimerNum.value = Number(data.coolDownTimer)
				ctimer()
			}else{
				cDownTimer.value = Date.now()
			}
			if(data.totalNums == data.receiveNums){
				getLoading.value = true
			}
		},(err) => {
			errMsg.value = err.msg
		})
		
		const screenWidth = window.screen.width;
		const screenHeight = window.screen.height;
		console.log('屏幕宽===='+screenWidth)
		console.log('屏幕高===='+screenHeight)
	}
	const getRewardInfo = () => {
		iaaSdk.sdkDialogRewardInfo((data) => {
			rewardInfos.value = Object.assign({},data)
			rewardProps.value = JSON.parse(data.items)
			keyList.value = JSON.parse(data.rareItems)
			if(Number(data.coolDownTime) > 0){
				cDownTime.value = Date.now() + 1000 * Number(data.coolDownTime)
				cTimeNum.value = Number(data.coolDownTime)
				ctime()
			}else{
				cDownTime.value = Date.now()
			}
		},(err) => {
			errMsg.value = err.msg
		})
	}
	const handleTypes = (val) => {
		if(cTimer.value){
			clearInterval(cTimer.value)
			cTimer.value = null
		}
		if(cTime.value){
			clearInterval(cTime.value)
			cTime.value = null
		}
		if(val == 'rewardGift'){
			getRewardInfo()
		}else{
			getInfo()
		}
		activeType.value = val
	}
	const handleCommit = () => {
		if(errMsg.value){
			ElMessage({
			    message: '出错了',
			    type: 'error',
				plain: true
			})
			return false
		}
		if(getLoading.value){
			return false
		}
		getLoading.value = true
		iaaSdk.sdkVideoAd((data) => {
			iaaSdk.sdkPostMsg({ action: 'playAds', data })
			getLoading.value = false
		},(res)=>{
			getLoading.value = false
			if(res.code == 202){//使用免广告卡了
				getInfo()
			}
		})
	}
	// 看广告获取钥匙
	const handleAdKeys = () => {
		if(getKeyLoading.value){
			return false
		}
		if(rewardInfos.value.viewNum > 1){
			showRewardGzh.value = true
		}else{
			getKeyLoading.value = true
			iaaSdk.sdkVideoAdKeys((data) => {
				iaaSdk.sdkPostMsg({ action: 'playAdsZp', data })
				getKeyLoading.value = false
			},(res)=>{
				getKeyLoading.value = false
			})
		}
	}
	// 领取钥匙
	const handleGetKeys = (row) => {
		if(row.isOpen == 0 || row.isGet == 1){//未达到条件或者已经领取了
			return false
		}
		iaaSdk.sdkRewardGetKeys(row.id,(data) => {
			getRewardInfo()
		})
	}
	// 抽奖
	const handleDraw = (num) => {
		if(rewardInfos.value.turntablePropNum < num){
			ElMessage({
			    message: '您的钥匙还不够抽奖哦，快去看广告获取钥匙吧～',
			    type: 'error',
				plain: true
			})
			return false
		}
		if(rewardInfos.value.turntableNum && Number(rewardInfos.value.turntableNum) + num > 20){//每天只能抽取20次
			ElMessage({
			    message: '今日抽取次数已达上限～',
			    type: 'error',
				plain: true
			})
			return false
		}
		iaaSdk.sdkRewardGetProp(num,(data) => {
			showRewards(data)
			getRewardInfo()
		})
	}
	const showRewards = (data) => {
		showAdReward.value = true
		rewardsList.value = Object.assign([],data)
	}
	const handleClose = () => {
		console.log('关闭弹窗')
		useIaaStore().setSecretParams().then(() => {
			iaaSdk.sdkPostMsg({ action: 'close' })
		})
	}
	const cancel = () => {
		showAdRules.value = false
		showAdReward.value = false
		showRewardRules.value = false
		showRewardGzh.value = false
	}
	watch(secretParams, (newVal,oldVal) => {
		if(newVal){
			getInfo()
		}
	},{ deep:true })
</script>

<style lang="less" scoped>
	@width: 90vw;@height: calc(@width / 4 * 7);
	@maxWidth: 45rem;
	@primary: #FE551F;
	@white: #FFF;
	@light: #05B700;
	.rewardPop{
		width: 100%;height: 100%;margin: 0 auto;padding: 2%;box-sizing: border-box;
		display: flex;align-items: center;flex-wrap: wrap;
		.props{
			width: calc(5.6rem * 4);height: 80%;overflow-y: scroll;margin: 0 auto;
			display: flex;align-items: center;justify-content: flex-start;flex-wrap: wrap;
		}
		.items{
			width: 4.8rem;height: 4.8rem;padding: 0.8rem;box-sizing: border-box;
			background: url('/iaa/popupIconbg.png') no-repeat center;
			background-size: 100%;margin: 0.4rem;position: relative;
			img{
				width: 100%;
			}
			.nums{
				position: absolute;right: 0.3rem;bottom: 0;
				font-size: 1rem;
			}
		}
		p{
			width: 100%;text-align: center;font-size: 1.6rem;
		}
	}
	.rulesPop{
		width: 94%;height: 90%;margin: 3% 3% 4%;overflow-y: scroll;font-size: 1.3rem;
	}
	.gzhPop{
		width: 100%;
		img{
			width: 100%;
		}
	}
	.popupBox{
		width: 100%;height: 100vh;max-width: @maxWidth;margin: 0 auto;position: relative;overflow: hidden;
		// background: rgba(0,0,0,0.6);
		.menuBtns{
			position: absolute;width: 4rem;height: 20rem;
			left: 0;top: 50%;transform: translate(0,-60%);
			ul{
				display: flex;flex-wrap: wrap;width: 100%;height: 100%;
				li{
					display: inline-flex;align-items: center;justify-content: center;
					width: 100%;height: 50%;writing-mode: vertical-lr;
					background: url('/iaa/popupMenu.png') no-repeat center;
					background-size: 100%;
					font-size: 2rem;color: @primary;
					padding-right: 0.5rem;box-sizing: border-box;
					&.active{
						background: url('/iaa/popupMenuActive.png') no-repeat center;
						background-size: 100%;
						color: @white;
					}
				}
			}
		}
		.closeBtn{
			position: absolute;width: 3rem;height: 3rem;
			right: calc((100% - @width) / 2);top: calc((100vh - @width / 4 * 7) / 2 + @width / 4 * 7 * 0.11);
			background: url('/iaa/popupClose.png') no-repeat center;
			background-size: 100%;
		}
		.popupCon{
			// width: 32rem;height: 56rem;
			width: @width;height: @height;
			position: absolute;left: 50%;top: 50%;
			transform: translate(-46%,-50%);
			&.superGift{
				background: url('/iaa/popupBg.png') no-repeat center;
				background-size: 100%;
				.cons{
					width: 100%;height: 52%;margin-top: 7%;
					p{ text-align: center; }
					.des{
						font-size: 1.3rem;height: 12%;
					}
					.nums{
						font-size: 1.8rem;height: 15%;
					}
					.icons{
						width: 42%;height: 66%;margin: 0 auto;
						display: flex;align-items: center;justify-content: space-between;
						.props{
							width: 6rem;height: 6rem;padding: 0.5rem;box-sizing: border-box;
							background: url('/iaa/popupIconbg.png') no-repeat center;
							background-size: 100%;position: relative;
							img{
								width: 100%;
							}
							span{
								display: inline-block;position: absolute;right: 0.2rem;bottom:0;font-size: 1.2rem;
							}
						}
						.simg{
							width: 1.6rem;height: 1.6rem;margin-left: 0.5rem;
						}
						span{
							font-size: 2rem;
						}
					}
				}
				.btns{
					width: 42%;margin: 4% auto;
					.ads{
						display: flex;align-items: center;justify-content: center;
						img{
							width: 2.4rem;margin-right: 0.5rem;
						}
						span{
							color: @light;font-size: 1.2rem;
						}
					}
					.btnGet{
						width: 85%;height: 3rem;text-align: center;line-height: 3rem;color: @white;
						margin: 3% auto 0;font-size: 1.6rem;
						background: url('/iaa/popupBtnbg.png') no-repeat center;
						background-size: 100%;
						&.disabled{
							filter: grayscale(1);
						}
					}
					.timer{
						text-align: center;
						/deep/.el-statistic__content{
							color: @light;font-size: 1.6rem;
						}
					}
				}
			}
			&.rewardGift{
				background: url('/iaa/popupBg2.png') no-repeat center;
				background-size: 100%;
				.rulesBox{
					display: flex;align-items: center;justify-content: flex-end;
					width: 90%;margin: 7% auto 0;height: 7%;
					img{
						width: 2rem;height: 2rem;margin-right: 0.5rem;
					}
					span{
						font-size: 1.2rem;
					}
				}
				.props{
					width: 92%;height: 17%;margin: 0 auto;overflow-y: hidden;
					ul{
						padding: 6% 0;width: 100%;height: 100%;box-sizing: border-box;
						li{
							float: left;margin-left: 0.5rem;
							width: calc(100% / 6 - 0.5rem);
							padding: 0 0.5rem;box-sizing: border-box;position: relative;
							img{
								width: 100%;
							}
							span{
								position: absolute;bottom: -0.4rem;right: 0.3rem;
							}
						}
					}
				}
				.btns{
					width: 86%;height: 14%;margin: 2% auto 0;
					display: flex;align-items: flex-start;justify-content: center;
					.items{
						width: 50%;height: 100%;
						.timer{
							text-align: center;
							/deep/.el-statistic__content{
								color: @light;font-size: 1.6rem;
							}
						}
						.btnDraw{
							width: 72%;height: 56%;color: #fff;margin: 0 auto;
							background: url('/iaa/popupBtn1.png') no-repeat center;
							background-size: 100%;font-size: 1.4rem;
							display: flex;align-items: center;justify-content: center;
							&.videos{
								background-size: 120%;border-radius: 0.5rem;width: 88%;box-shadow: inset 0 0 0.5rem 0.2rem rgba(#FC586C, 0.6);
								&.disabled{
									filter: grayscale(1);
								}
							}
							&.bg10{
								background: url('/iaa/popupBtn10.png') no-repeat center;
								background-size: 100%;
							}
							.video{
								display: inline-block;width: 1.8rem;height: 1.6rem;
								background: url('/iaa/popupVideo.png') no-repeat center;
								background-size: 100%;margin-right: 0.3rem;margin-left: -0.3rem;
							}
						}
						p{
							display: inline-flex;align-items: center;justify-content: center;
							width: 100%;
							.key{
								display: inline-block;width: 1.6rem;height: 1.6rem;
								background: url('/iaa/popupKey.png') no-repeat center;
								background-size: 100%;margin-right: 0.2rem;
							}
							span{
								color: @light;
							}
						}
					}
				}
				.rules{
					width: 100%;text-align: center;font-size: 0.9rem;
				}
				.keysList{
					width: 86%;margin: 2% auto 0;height: 18%;
					ul{
						display: flex;align-items: center;justify-content: center;
						li{
							display: inline-block;padding: 0.5rem;box-sizing: border-box;
							width: 25%;
							.items{
								width: 100%;font-size: 0;position: relative;
								img{
									width: 100%;
								}
								span{
									display: inline-block;position: absolute;right: 0.3rem;bottom: 0;
									font-size: 1.1rem;color: @white;text-shadow: 0 0.1rem 0 #000;
								}
								&.active{
									&::after{
										position: absolute;left: -0.5rem;top: -0.5rem;
										content: ' ';width: 3rem;height: 2.4rem;
										background: url('/iaa/popupGet.png') no-repeat center;
										background-size: 100%;
									}
								}
								
							}
							p{
								text-align: center;font-size: 0.75rem;
								width: 120%;margin-left: -10%;
							}
						}
					}
				}
			}
			.conBox{
				position: absolute;width: 78%;height: 58%;color: @primary;
				left: 50%;top: 50%;transform: translate(-50%,-50%);
				.title{
					font-size: 2.2rem;letter-spacing: 0.1rem;height: 8%;
					width: 100%;text-align: center;margin-top: 14%;
				}
			}
			
		}
		
	}
	
	@media all and (orientation : landscape) and (min-width: 600px) and (min-height: 600px){
		@width: 35rem;
		.popupBox .popupCon{
			width: @width;height: calc(@width / 4 * 7);
		}
		.popupBox .closeBtn{
			right: calc((100% - @width) / 2);top: calc((100vh - @width / 4 * 7) / 2 + @width / 4 * 7 * 0.11);
		}
	}
	@media all and (orientation : landscape) and (min-width: 600px) and (max-height: 450px){
		@width: 80vh;
		.popupBox .closeBtn{
			right: calc((100% - @width) / 2);top: calc((100vh - @width / 4 * 7) / 2 + @width / 4 * 7 * 0.11);
		}
		.popupBox .popupCon{
			width: @width;height: calc(@width / 4 * 7);
			&.superGift,&.rewardGift{
				background-position: center bottom;
			}
			.conBox{
				top: 54%;
				.title{
					font-size: 2rem;
				}
				.btns{
					.ads{
						img{
							width: 1.8rem;
						}
					}
				}
			}
			&.rewardGift{
				.rulesBox{
					margin: 10% auto 0;
					img{
						width: 1.6rem;height: 1.6rem;
					}
				}
				.props{
					height: 15%;
				}
				.btns .items .btnDraw.videos{
					width: 90%;
				}
			}
		}
	}
	@media all and (orientation : portrait) and (min-width: 600px) and (min-height: 600px){
		@width: 35rem;
		.popupBox .popupCon{
			width: @width;height: calc(@width / 4 * 7);
		}
		.popupBox .closeBtn{
			right: calc((100% - @width) / 2);top: calc((100vh - @width / 4 * 7) / 2 + @width / 4 * 7 * 0.11);
		}
	}
	@media all and (orientation : portrait) and (max-width: 375px){
		.popupBox .popupCon .conBox .title{
			margin-top: 13%;
		}
	}
</style>