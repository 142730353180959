import Cookies from 'js-cookie'

const TokenKey = 'pQp-Token'
const NameKey = 'pQp-Name'
const OpenidKey = 'pQp-Openid'
const UseridKey = 'pQp-Users'
export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getName() {
  return Cookies.get(NameKey)
}

export function setName(name) {
  return Cookies.set(NameKey, name)
}

export function removeName() {
  return Cookies.remove(NameKey)
}

export function getOpenid() {
  return Cookies.get(OpenidKey)
}

export function setOpenid(id) {
  return Cookies.set(OpenidKey, id)
}

export function removeOpenid() {
  return Cookies.remove(OpenidKey)
}

export function getUserid() {
  return Cookies.get(UseridKey)
}

export function setUserid(user) {
  return Cookies.set(UseridKey, user)
}

export function removeUserid() {
  return Cookies.remove(UseridKey)
}