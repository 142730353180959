<template>
  <section class="bannerBox" :class="{'pc':!isMobile(),'m':isMobile()}">
	  <div class="bannerContent">
		  <!-- <h2>인기 게임 쿠폰</h2> -->
		  <!-- <a>모두 선택하여 구매</a> -->
	  </div>
  </section>
  <!-- <section class="richText bg1">
  	  <div class="richTextContent">
  		  <h2>您可以在这里享受最高的福利</h2>
  		  <p>이것은 인기 게임 교환 코드의 스토어입니다. 관심 있다면 100원만 있으면 게임 내 혜태 아이템을 획득할 수 있습니다.</p>
  	  </div>
  </section> -->
  <section class="bg1">
	  <div class="listBox" :class="{'pc':!isMobile(),'m':isMobile()}">
		  <!-- <h2 class="title">游戏</h2> -->
		  <div class="listItem">
			  <ul>
				<template v-for="(item,index) in gameList" :key="index">
					<li class="item" v-if="item.myGameId">
						<div class="imgBox">
							<router-link :to="'/products/detail/'+item.gameId+'/'+item.myGameId">
								<img :src="item.gameIcon" />
							</router-link>
						</div>
						<div class="desBox">
							<h3 class="title">
								<router-link :to="'/products/detail/'+item.gameId+'/'+item.myGameId">
									{{item.gameKrName}}<span v-if="item.h5Discount < 1" style="color: red;">({{ (item.h5Discount*10).toFixed(countDecimals(item.h5Discount)) }}折)</span>
								</router-link>
							</h3>
							<p class="price">
								<!-- <span class="original">¥{{item.originalPrice}} CNY</span>
								<span class="onsale">¥{{item.presentPrice}} CNY</span> -->
								<router-link class="btn" :to="'/products/detail/'+item.gameId+'/'+item.myGameId">
									<!-- <span class="onsale">立即试玩</span> -->
									<el-button type="primary">充值</el-button>
								</router-link>
							</p>
						</div>
					</li>
				</template>
			  </ul>
		  </div>
		  
		  <!-- <swiper
		    :effect="'coverflow'"
			:grabCursor="true"
			:centeredSlides="true"
			:slidesPerView="'auto'"
			:coverflowEffect="{
			  rotate: 50,
			  stretch: 0,
			  depth: 100,
			  modifier: 1,
			  slideShadows: false,
			}"
			:pagination="true" 
		  	:style="{
		  	  '--swiper-pagination-color': '#000'
		  	}"
		    class="mySwiper"
		    >
		    <swiper-slide v-for="(item,index) in adList" :key="index">
		  		<div class="ad">
		  			<img :src="item.url">
		  		</div>
		  	</swiper-slide>
		  </swiper> -->
	  </div>
  </section>

</template>

<script setup>
import { ref, onMounted } from "vue";
import { getGameList } from '@/api/index'
import { Swiper, SwiperSlide } from 'swiper/vue';
import { isMobile,iswxBrowser,wxcode } from '@/utils/common.js'
import { useRouter,useRoute } from 'vue-router'
import useUserStore from '@/store/modules/user'
import SwiperCore, { EffectCoverflow,Pagination,Navigation } from 'swiper/core';
SwiperCore.use([EffectCoverflow,Pagination,Navigation]);

import "swiper/components/effect-coverflow";
// let router = useRouter();
// let route = useRoute();
const adList = ref([
	{ url: require('@/assets/images/ad1.png') },
	{ url: require('@/assets/images/ad2.png') },
	{ url: require('@/assets/images/ad3.png') },
	{ url: require('@/assets/images/ad4.png') }
])

const gameList = ref([])
const countDecimals = (num) => {
    // 将数字转换为字符串
    let numStr = num.toString();
    
    // 判断是否包含小数点
    if (!numStr.includes('.')) {
        return 0; // 如果没有小数点，则返回0
    }
    
    // 使用split方法以小数点分割
    let [integerPart, decimalPart] = numStr.split('.');
    
    // 返回小数部分的长度
    return decimalPart.length-1;
}
onMounted(()=>{
	// if(isMobile() && iswxBrowser()){
	// 	if(!useUserStore().openid){
	// 		wxcode()
	// 	}
	// }
	getGameList().then((res)=>{
		const { hotGame } = res.data
		gameList.value = hotGame
	})
})

</script>
<style lang="less">
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>
.bannerBox{
	width: 100%;height: 15.2vw;min-height: 187px;overflow: hidden;
	background: url('~@/assets/images/banner.jpg') no-repeat center top;
	background-size: 100%;position: relative;z-index: 1;
	&.m{
		background: url('~@/assets/images/banner_m.jpg') no-repeat center top;
		background-size: 100%;
		height: 13.125rem;min-height: 200px;
	}
	.bannerContent{
		position: absolute;color: #fff;text-align: center;z-index: 1111;
		left: 50%;bottom: 6rem;transform: translate(-50%,0);
		h2{
			font-size: 5.2rem;font-weight: 400;
		}
		p{
			margin-top: 1rem;font-size: 1.6rem;
		}
		a{
			display: inline-block;padding: 1.5rem 3rem;border: 1px solid #fff;color: #fff;
			letter-spacing: 0.1rem;margin-top: 2rem;box-sizing: border-box;font-size: 1.6rem;
			transition: box-shadow 0.1s ease;
			&:hover{
				box-shadow: 0 0 2px rgb(255,255,255);
			}
		}
	}
}
.richText{
	padding: 40px;box-sizing: border-box;padding-bottom: 0;
	.richTextContent{
		width: 100%;text-align: center;
		padding: 0 5rem;margin: 0 auto;box-sizing: border-box;
		h2{
			font-size: 2.4rem;font-weight: 400;
		}
		p{
			margin-top: 2rem;color: rgba(18,18,18,0.75);font-size: 1.6rem;
		}
	}
}
.mySwiper{
	margin-top: 3rem;
	.ad{
		position: relative;width: 100%;height: 170px;overflow: hidden;
		img{
			height: 100%;position: absolute;
			top: 0;left: 50%;transform: translate(-50%,0);
		}
	}
}
</style>
