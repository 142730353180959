<template>
	<div v-html="aliform"></div>
</template>

<script setup>
	import { onMounted,nextTick,ref } from 'vue'
	import { useRoute,useRouter } from 'vue-router';
	const router = useRouter();
	const aliform = ref(null)
	onMounted(() => {
		let form = router.currentRoute.value.query.htmls
		console.log(form)
		aliform.value = form
		nextTick(() => {
			if(aliform.value && document.forms[0]){
				document.forms[0].submit();
			}
		})
	})
</script>

<style>
</style>