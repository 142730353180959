import request from "@/utils/request";

// 积分明细
export function pointList(query) {
  return request({
    url: '/pointDetail/getList',
    headers: {
      isToken: true
    },
    method: 'get',
	params: query
  })
}

// 代金券余额
export function couponInfo(query) {
  return request({
    url: '/pointDetail/getCouponInfo',
    headers: {
      isToken: true
    },
    method: 'get',
	params: query
  })
}
// 代金券明细
export function couponList(query) {
  return request({
    url: '/pointDetail/getCouponList',
    headers: {
      isToken: true
    },
    method: 'get',
	params: query
  })
}

// 积分道具列表
export function pointGiftList(query) {
  return request({
    url: '/pointGift/getGift',
    headers: {
      isToken: true
    },
    method: 'get',
	params: query
  })
}

// 积分兑换道具
export function exchangePoint(data) {
  return request({
    url: '/pointGift/exchangePoint',
    headers: {
      isToken: true
    },
    method: 'post',
	data: data
  })
}

// 用户信息
export function getUserInfo(query) {
  return request({
    url: '/userSonInfo/getUserInfo',
    headers: {
      isToken: true
    },
    method: 'get',
	params: query
  })
}