<template>
	<div class="maxBox" :class="{'pc':!isMobile(),'m':isMobile()}">
		<h1>退款协议</h1>
		<div class="textDes">
			<p>我们将全力为所有用户提供最好的产品体验和服务.</p>
			<br>
			<br>
			<p>购买前，请仔细阅读产品详情和注意事项，本产品为虚拟产品，请按程序进行更换.</p>
			<p>用户购买成功后
			</p><p>请在该游戏中进行交换。您也可以联系我们的微信客服（{{ wxkf }}）.</p>
			<br>
			<br>
			<p>1. 因本人原因想退货或更换时 
			</p><p>因为我们的产品是虚拟产品，所以只能使用一次。所以收到优惠券后，无论是否使用，都不能退货或更换.</p>
			<br>
			<br>
			<p>2.因产品原因想退货或更换时
			</p><p>1.如果您没有收到优惠券：电子邮件{{ email }}如果您通过联系申请补发邮件，我们将在工作日3天内补发。.
			</p><p>2.收到优惠券，但无法正常使用的情况：提供无法激活的截图。{{ email }}通过电子邮件发送即可补办，工作日3天内补办.</p> 
			<br>
			<br>
			<br>
			<p>请联系我们
			</p><p>如果您有其他问题或疑问，请发送电子邮件：{{ email }}或者联系我们的微信客服（{{ wxkf }}），谢谢您的联系.
			</p>
		</div>
	</div>
</template>
<script setup>
	import { ref, onMounted } from "vue";
	import { isMobile } from '@/utils/common.js'
	const email = ref('lijiahui@7pa.com')
	const wxkf = ref('btapp8')
</script>
<style lang="less">
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>
	h1{width: 100%;}
	.maxBox{
		flex-wrap: wrap;
		&.m{
			padding: 28px 16px;box-sizing: border-box;
			h1{
				font-size: 26px;
			}
		}
	}
	
</style>
