import request from "@/utils/request";
import { encryptByDES } from '@/utils/3DES.js'
// 账密登录
export function login(data) {
  return request({
    url: '/userInfo/h5/skip/login',
    headers: {
      isToken: false
    },
    method: 'post',
	data: data
  })
}

// // 注册
// export function register(data) {
//   return request({
//     url: '/user/login/add',
//     headers: {
//       isToken: false
//     },
//     method: 'post',
// 	data: data
//   })
// }

// // 用户信息
// export function getinfo() {
//   return request({
//     url: '/function/shoppingCart/getUserInfo',
//     headers: {
//       isToken: true
//     },
//     method: 'post'
//   })
// }

// 手机短信登录
export function telLogin(data) {
  return request({
    url: '/userInfo/h5/loginByPhone',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}
// 短信验证码
export function getCodeSms(phone) {
  return request({
    url: '/getCode/'+phone,
    headers: {
      isToken: false
    },
    method: 'get'
  })
}
// 获取微信openid
export function getWxOpenid(data) {
  return request({
    url: '/orderInfo/getOpenId',
    headers: {
      isToken: false
    },
    method: 'post',
	data: data
  })
}
// 小号
export function getSubUser(data) {
  return request({
    url: '/userSonInfo/getUserSonInfoList',
    headers: {
      isToken: true
    },
    method: 'post',
    data: data
  })
}
// 小号角色
export function getSubUserRole(data) {
  return request({
    url: '/userSonInfo/getUserSonRoleInfo',
    headers: {
      isToken: true
    },
    method: 'post',
    data: data
  })
}
// // 验证码
// export function getCodeImg() {
//   return request({
//     url: '/captchaImage',
//     headers: {
//       isToken: false
//     },
//     method: 'get'
//   })
// }