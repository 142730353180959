<template>
	<el-drawer
	    v-model="showPays"
	    direction="btt"
	    size="78%"
	    class="bttDrawer"
		:append-to-body="true"
	    :before-close="cancel"
	  >
	  <template #header>
		<h4 class="titleBox">确认购买信息</h4>
	  </template>
	    <div class="orderInfo" v-loading="imgLoading">
			<p class="goodPrice"><i class="unit">¥</i>{{ good.realPrice }}<i class="oprice">（ ¥ {{ good.goodPrice }} )</i></p>
			<div class="lineItems">
				<!-- <img class="goodIcon" :src="good.icon" /> -->
				<div>
					<p class="goodName">{{ good.goodName }}</p>
					<p class="freeCoin"><i class="tag">送</i>{{ good.realPrice }}积分</p>
				</div>
			</div>
			<div class="lineItems">
				<span class="labelTxt">角色信息</span>
				<span class="valTxt">{{ good.roleName }}</span>
			</div>
			<div class="payTyps">
				<template v-for="(item,index) in payTypeList" :key="index">
					<div class="payLines" @click="handlePayType(item)" v-if="item.isShow">
						<div class="item">
							<svg v-if="item.icon=='alipay'" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#1777FF" class="bi bi-alipay" viewBox="0 0 16 16">
							  <path d="M2.541 0H13.5a2.551 2.551 0 0 1 2.54 2.563v8.297c-.006 0-.531-.046-2.978-.813-.412-.14-.916-.327-1.479-.536-.303-.113-.624-.232-.957-.353a12.98 12.98 0 0 0 1.325-3.373H8.822V4.649h3.831v-.634h-3.83V2.121H7.26c-.274 0-.274.273-.274.273v1.621H3.11v.634h3.875v1.136h-3.2v.634H9.99c-.227.789-.532 1.53-.894 2.202-2.013-.67-4.161-1.212-5.51-.878-.864.214-1.42.597-1.746.998-1.499 1.84-.424 4.633 2.741 4.633 1.872 0 3.675-1.053 5.072-2.787 2.08 1.008 6.37 2.738 6.387 2.745v.105A2.551 2.551 0 0 1 13.5 16H2.541A2.552 2.552 0 0 1 0 13.437V2.563A2.552 2.552 0 0 1 2.541 0Z"/>
							  <path d="M2.309 9.27c-1.22 1.073-.49 3.034 1.978 3.034 1.434 0 2.868-.925 3.994-2.406-1.602-.789-2.959-1.353-4.425-1.207-.397.04-1.14.217-1.547.58Z"/>
							</svg>
							<svg v-else xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#07c160" class="bi bi-wechat" viewBox="0 0 16 16">
							  <path d="M11.176 14.429c-2.665 0-4.826-1.8-4.826-4.018 0-2.22 2.159-4.02 4.824-4.02S16 8.191 16 10.411c0 1.21-.65 2.301-1.666 3.036a.324.324 0 0 0-.12.366l.218.81a.616.616 0 0 1 .029.117.166.166 0 0 1-.162.162.177.177 0 0 1-.092-.03l-1.057-.61a.519.519 0 0 0-.256-.074.509.509 0 0 0-.142.021 5.668 5.668 0 0 1-1.576.22ZM9.064 9.542a.647.647 0 1 0 .557-1 .645.645 0 0 0-.646.647.615.615 0 0 0 .09.353Zm3.232.001a.646.646 0 1 0 .546-1 .645.645 0 0 0-.644.644.627.627 0 0 0 .098.356Z"/>
							  <path d="M0 6.826c0 1.455.781 2.765 2.001 3.656a.385.385 0 0 1 .143.439l-.161.6-.1.373a.499.499 0 0 0-.032.14.192.192 0 0 0 .193.193c.039 0 .077-.01.111-.029l1.268-.733a.622.622 0 0 1 .308-.088c.058 0 .116.009.171.025a6.83 6.83 0 0 0 1.625.26 4.45 4.45 0 0 1-.177-1.251c0-2.936 2.785-5.02 5.824-5.02.05 0 .1 0 .15.002C10.587 3.429 8.392 2 5.796 2 2.596 2 0 4.16 0 6.826Zm4.632-1.555a.77.77 0 1 1-1.54 0 .77.77 0 0 1 1.54 0Zm3.875 0a.77.77 0 1 1-1.54 0 .77.77 0 0 1 1.54 0Z"/>
							</svg>
						<span style="margin-left: 5px;">{{ item.label }}</span>
						</div>
						<el-icon size="17" :color="item.color" v-if="payType == item.value"><CircleCheck /></el-icon>
						<span class="emp" v-else></span>
					</div>
				</template>
			</div>
			<a v-if="alipayUrl" class="payBtn" :style="{backgroundColor:formatPaytype(payType,'color')}" @click="handleAlipay" >立即支付</a>
			<a v-else-if="wxpayUrl || wxpayParams.prepayId" :style="{backgroundColor:formatPaytype(payType,'color')}" class="payBtn" @click="handleWxpay" >立即支付</a>
			<a v-else class="normalBtn">请先选择支付方式</a>
		</div>
	</el-drawer>
</template>

<script setup>
	import wx from 'weixin-jsapi'
	import { ElMessage, ElMessageBox } from 'element-plus'
	import { getCurrentInstance, reactive, ref, onMounted, defineProps, defineEmits, computed } from 'vue'
	import { payorder, payWxmoney, payStatus, payAlimoney } from '@/api/pay'
	import { useRoute,useRouter } from 'vue-router';
	import { isMobile } from '@/utils/common.js'
	import useUserStore from '@/store/modules/user'
	import QRCode from 'qrcodejs2'
	import variables from '@/assets/css/variables.less'
	const variablesStyles = ref(variables)
	const { proxy } = getCurrentInstance();
	const router = useRouter();
	const props = defineProps({
		good: {
			type: Object
		},
		showPay: {
			type: Boolean,
			default: false
		},
		gameid: {
			type: Number,
			default: 0
		}
	})
	const emits = defineEmits(['cancel'])
	const cancel = () => {
		clearTimer()
		emits('cancel')
	}
	const iswxBrowser = () => {
		var isWechatBrowser = /micromessenger/i.test(navigator.userAgent);
		return isWechatBrowser
	}
	const openids = computed(() => {
		return useUserStore().getOpenIds() || ''
	})
	const showPays = computed(() => {
		return props.showPay
	})
	const gameId = computed(() => {
		return props.gameid
	})
	const goods = computed(() => {
		console.log(goods)
		return props.good
	})
	const role = computed(() => {
		return useUserStore().roleInfo
	})
	const imgLoading = ref(false)
	const alipay = ref(1)
	const wxpay = ref(2)
	const payType = ref(0)
	const payTypeList = reactive([
		{ label: '支付宝', value: 1, icon: 'alipay', color: variablesStyles.value.alipayColor, isShow: iswxBrowser() ? false : true },
		{ label: '微信支付', value: 2, icon: 'wechat-pay', color: variablesStyles.value.weixinColor, isShow: true }
	])
	const formatPaytype = (val,param = 'label') => {
		let obj = payTypeList.find((item) => item.value == val)
		return obj[param] || ''
	}
	const countTimer = ref(null)
	const initNum = ref(60)//5分钟
	const countNum = ref(initNum.value)
	const payState = ref(100)//100待支付
	const wxpayUrl = ref('')
	const wxpayParams = ref({})
	const alipayUrl = ref('')
	onMounted(() => {
		if(role.value.roleId && goods.value.goodId){
			imgLoading.value = true
		}
	})
	const hasOpenId = () => {
		let obj = openids.value.find((item) => item.gameId == gameId.value)
		return obj
	}
	const timer = () => {
		countTimer.value = setInterval(() => {
			if(countNum.value > 0){
				countNum.value --
				getOrderStatus()
			}else{
				clearTimer()
			}
		},1000)
	}
	const clearTimer = () => {
		clearInterval(countTimer.value)
		countTimer.value = null
	}
	const showPaySuccess = () => {
		ElMessageBox.confirm(
		    '请前往游戏内查收商品',
		    '支付成功',
		    {
		      confirmButtonText: '好的',
			  showCancelButton: false,
		      type: 'success',
		    }
		  )
		    .then(() => {
				cancel()
		    })
			.catch(() => {
				cancel()
			})
		    
	}
	const getOrderStatus = () => {
		let args = {}
		if(goods.value.type){
			args.goodType = goods.value.type
		}else{
			args.goodType = 0
		}
		payStatus({ id: goods.value.orderId, ...args }).then((res) => {
			payState.value = res.data
			if(res.data == 400){//成功
				clearTimer()
				showPaySuccess()
			}else if(res.data == 900){//失败
				clearTimer()
			}else{//100待支付
				console.log('继续轮循=====')
			}
		})
	}
	const handlePayType = (row) => {
		clearTimer()
		alipayUrl.value = ''
		wxpayUrl.value = ''
		wxpayParams.value = {}
		countNum.value = initNum.value
		payType.value = row.value
		if(row.value == wxpay.value){
			refreshWxpay()
		}else{
			refreshAlipay()
		}
		
	}
	const refreshAlipay = () => {
		imgLoading.value = true
		let args = {}
		if(goods.value.type){
			args.goodType = goods.value.type
		}else{
			args.goodType = 0
		}
		payAlimoney({ orderId: goods.value.orderId, goodName: goods.value.goodName, ...args }).then((res) => {//99二维码，h5支付默认不传
			console.log(res)
			imgLoading.value = false
			
			const urlParams = {
			  name:'Payali',
			  params: { id: goods.value.orderId },
			  query: { htmls: res.data }
			};
			const resolved = router.resolve(urlParams)
			alipayUrl.value = 'https://pay.7pa.com'+resolved.href
			
		}).catch(() => {
			imgLoading.value = false
		})
	}
	const refreshWxpay = () => {
		imgLoading.value = true
		let args = {
			orderId: goods.value.orderId, 
			goodName: goods.value.goodName, 
			osType: iswxBrowser() ? 100 : 99,
			goodType: goods.value.type || 0
		}
		if(iswxBrowser()){
			args.code = useUserStore().wcode
			args.openId = hasOpenId().openId
		}
		payWxmoney(args).then((res) => {//99外部浏览器，100微信浏览器
			console.log(res)
			imgLoading.value = false
			if(isMobile()){
				if(iswxBrowser()){
					useUserStore().clearCode()
					wxpayParams.value = res.data
				}else{
					wxpayUrl.value = res.data
					timer()
				}
			}
		}).catch(() => {
			if(iswxBrowser() && useUserStore().wcode){
				useUserStore().clearCode()
			}
			imgLoading.value = false
		})
		
	}
	
	const wxpayFun = (data) => {
		wx.config({
			debug: false, // 这里一般在测试阶段先用ture，等打包给后台的时候就改回false, 
			appId: data.appId, // 必填，公众号的唯一标识 
			timestamp: data.timestamp, // 必填，生成签名的时间戳     
			nonceStr: data.nonceStr, // 必填，生成签名的随机串 
			signature: data.paySign, // 必填，签名 
			jsApiList: ['chooseWXPay', 'checkJsApi'] // 必填，需要使用的JS接口列表 
		})
		wx.ready(() => {
			wx.chooseWXPay({
				timestamp: data.timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符     
				nonceStr: data.nonceStr, // 支付签名随机串，不长于 32 位         
				package: 'prepay_id='+data.prepayId, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）     
				signType: data.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'     
				paySign: data.paySign, // 支付签名     
				success: function(payRes) { 
				// 支付成功后的回调函数     
					console.log(payRes)
					showPaySuccess()
				},
				cancel: function(payRes) {
					console.log(payRes)
					ElMessage({
						message: '取消支付',
						type: 'error',
					})
				},
				fail: function(payRes) {
					console.log(payRes)
					ElMessage({
						message: '支付失败',
						type: 'error',
					})
				}
			})
		})
	}
	const handleAlipay = () => {
		window.location.href = alipayUrl.value
		// window.open(alipayUrl.value)
	}
	const handleWxpay = () => {
		if(iswxBrowser()){
			wxpayFun(wxpayParams.value)
		}else{
			window.location.href = wxpayUrl.value
			// window.open(wxpayUrl.value)//ios这个跳转不行
		}
	}
	
</script>

<style lang="less">
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>
	.payBtn{
		display: inline-block;color:#fff;
		width: 100%;line-height: 36px;text-align: center;margin-left: 0;
		border-radius: 10px;margin-top: 60px;
	}
	.normalBtn{
		display: inline-block;background: #fff;
		width: 100%;line-height: 36px;text-align: center;margin-left: 0;
		border-radius: 10px;margin-top: 60px;color: #999;
	}

	.orderInfo{
		font-size: 14px;
		.lineItems{
			width: 100%;margin: 8px auto 0;background: #fff;box-sizing: border-box;
			border-radius: 10px;padding: 14px 16px;
			display: inline-flex;align-items: center;justify-content: space-between;
			.tag{
				display: inline-block;width: 18px;height: 18px;font-size: 12px;
				background: #00c3ff;color: #fff;font-style: normal;line-height: 18px;text-align: center;
				border-radius: 2px;margin-right: 3px;
			}
			.freeCoin{
				color: #999;display: flex;align-items: center;margin-top: 3px;
			}
			.goodIcon{
				width: 46px;height: 46px;border-radius: 12px;
			}
			.goodName{
				color: #0c0c0c;font-size: 16px;
			}
			.labelTxt{ color: #666;}
			.valTxt{ color: #0c0c0c;}
		}
		.goodPrice{
			text-align: center;color: red;font-size: 30px;margin-bottom: 20px;font-weight: 600;
			.oprice{
				font-size: 12px;text-decoration: line-through;color: #999;margin-left: 5px;font-weight: normal;
			}
			.unit{
				font-size: 16px;font-weight: normal;font-style: normal;margin-right: 5px;
			}
		}
		.goodTitle{
			text-align: center;margin: 10px 0;
		}
		.payTyps{
			display: flex;align-items: center;flex-wrap: wrap;
			width: 100%;margin: 8px auto 0;background: #fff;box-sizing: border-box;
			border-radius: 12px;padding: 8px 14px;
			.payLines{
				width: 100%;display: flex;align-items: center;justify-content: space-between;
				cursor: pointer;
				&:first-child{
					padding-bottom: 5px;
				}
				.emp{
					width: 15px;height: 15px;color:#fff;border-radius: 100%;display: inline-block;position: relative;
					border: 1px solid #ccc;box-sizing: border-box;
				}
			}
			.payLines + .payLines{
				border-top: 1px solid #f9fafc;padding-top: 5px;
			}
			.item{
				width: 42%;line-height: 36px;display: inline-flex;align-items: center;justify-content: left;
				font-size: 16px;
			}
		}
		
	}
</style>