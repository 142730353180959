<template>
	<el-dialog class="loginTabs" v-model="showPays" title="扫码支付" :width="isMobile()?`95%`:`500`" @close="cancel">
	    <div class="orderInfo">
			<p class="goodPrice">¥ {{ good.realPrice }}<i class="oprice">（ ¥ {{ good.goodPrice }} )</i></p>
			<p class="goodTitle">商品：{{ good.goodName }}</p>
			<div class="payTyps">
				<template v-for="(item,index) in payTypeList" :key="index">
					<div class="item" :style="{borderColor:payType == item.value?`${item.color}`:'#eee',color:payType == item.value?`${item.color}`:'#666'}" @click="handlePayType(item)">
						<svg v-if="item.icon=='alipay'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-alipay" viewBox="0 0 16 16">
						  <path d="M2.541 0H13.5a2.551 2.551 0 0 1 2.54 2.563v8.297c-.006 0-.531-.046-2.978-.813-.412-.14-.916-.327-1.479-.536-.303-.113-.624-.232-.957-.353a12.98 12.98 0 0 0 1.325-3.373H8.822V4.649h3.831v-.634h-3.83V2.121H7.26c-.274 0-.274.273-.274.273v1.621H3.11v.634h3.875v1.136h-3.2v.634H9.99c-.227.789-.532 1.53-.894 2.202-2.013-.67-4.161-1.212-5.51-.878-.864.214-1.42.597-1.746.998-1.499 1.84-.424 4.633 2.741 4.633 1.872 0 3.675-1.053 5.072-2.787 2.08 1.008 6.37 2.738 6.387 2.745v.105A2.551 2.551 0 0 1 13.5 16H2.541A2.552 2.552 0 0 1 0 13.437V2.563A2.552 2.552 0 0 1 2.541 0Z"/>
						  <path d="M2.309 9.27c-1.22 1.073-.49 3.034 1.978 3.034 1.434 0 2.868-.925 3.994-2.406-1.602-.789-2.959-1.353-4.425-1.207-.397.04-1.14.217-1.547.58Z"/>
						</svg>
						<svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-wechat" viewBox="0 0 16 16">
						  <path d="M11.176 14.429c-2.665 0-4.826-1.8-4.826-4.018 0-2.22 2.159-4.02 4.824-4.02S16 8.191 16 10.411c0 1.21-.65 2.301-1.666 3.036a.324.324 0 0 0-.12.366l.218.81a.616.616 0 0 1 .029.117.166.166 0 0 1-.162.162.177.177 0 0 1-.092-.03l-1.057-.61a.519.519 0 0 0-.256-.074.509.509 0 0 0-.142.021 5.668 5.668 0 0 1-1.576.22ZM9.064 9.542a.647.647 0 1 0 .557-1 .645.645 0 0 0-.646.647.615.615 0 0 0 .09.353Zm3.232.001a.646.646 0 1 0 .546-1 .645.645 0 0 0-.644.644.627.627 0 0 0 .098.356Z"/>
						  <path d="M0 6.826c0 1.455.781 2.765 2.001 3.656a.385.385 0 0 1 .143.439l-.161.6-.1.373a.499.499 0 0 0-.032.14.192.192 0 0 0 .193.193c.039 0 .077-.01.111-.029l1.268-.733a.622.622 0 0 1 .308-.088c.058 0 .116.009.171.025a6.83 6.83 0 0 0 1.625.26 4.45 4.45 0 0 1-.177-1.251c0-2.936 2.785-5.02 5.824-5.02.05 0 .1 0 .15.002C10.587 3.429 8.392 2 5.796 2 2.596 2 0 4.16 0 6.826Zm4.632-1.555a.77.77 0 1 1-1.54 0 .77.77 0 0 1 1.54 0Zm3.875 0a.77.77 0 1 1-1.54 0 .77.77 0 0 1 1.54 0Z"/>
						</svg>
					<span style="margin-left: 5px;">{{ item.label }}</span>
					</div>
				</template>
			</div>
			
			<div id="Qrcode" ref="qrCodeUrl" style="display: none;"></div>
			<div class="qrcodeImg" id="QrcodeShow" ref="qrCodeUrlShow" v-loading="imgLoading" :class="`code${payState}`"></div>
			<p class="qrcodeTxt" v-if="payType == alipay">支付宝扫码<span>，刷新支付状态倒计时<b style="color: red;">{{ countNum }}</b>s</span></p>
			<p class="qrcodeTxt" v-else-if="payType == wxpay">微信扫码<span>，刷新支付状态倒计时<b style="color: red;">{{ countNum }}</b>s</span></p>
			<p class="qrcodeTxt" v-else>请先选择支付方式</p>
			
		</div>
	</el-dialog>
</template>

<script setup>
	import { ElMessage, ElMessageBox } from 'element-plus'
	import { getCurrentInstance, reactive, ref, onMounted, defineProps, defineEmits, computed } from 'vue'
	import { payorder, payWxmoney, payAlimoney, payStatus } from '@/api/pay'
	import { isMobile } from '@/utils/common.js'
	import useUserStore from '@/store/modules/user'
	import QRCode from 'qrcodejs2'
	import variables from '@/assets/css/variables.less'
	const variablesStyles = ref(variables)
	const { proxy } = getCurrentInstance();
	const props = defineProps({
		good: {
			type: Object
		},
		showPay: {
			type: Boolean,
			default: false
		},
		gameid: {
			type: Number,
			default: 0
		}
	})
	const emits = defineEmits(['cancel'])
	const cancel = () => {
		clearTimer()
		emits('cancel')
	}
	const showPays = computed(() => {
		return props.showPay
	})
	const gameId = computed(() => {
		return props.gameid
	})
	const goods = computed(() => {
		console.log(goods)
		return props.good
	})
	const role = computed(() => {
		return useUserStore().roleInfo
	})
	const imgLoading = ref(false)
	const alipay = ref(1)
	const wxpay = ref(2)
	const payType = ref(0)
	const payTypeList = reactive([
		{ label: '支付宝', value: 1, icon: 'alipay', color: variablesStyles.value.alipayColor },
		{ label: '微信支付', value: 2, icon: 'wechat-pay', color: variablesStyles.value.weixinColor }
	])
	const formatPaytype = (val,param = 'label') => {
		let obj = payTypeList.find((item) => item.value == val)
		return obj[param] || ''
	}
	const qrCodeUrl = ref(null)
	const qrCodeUrlShow = ref(null)
	const countTimer = ref(null)
	const initNum = ref(300)//5分钟
	const countNum = ref(initNum.value)
	const payState = ref(100)//100待支付
	const wxpayUrl = ref('')
	onMounted(() => {
		if(role.value.roleId && goods.value.goodId){
			console.log('111')
			imgLoading.value = true
		}
	})
	// const handleWxpay = () => {
	// 	// window.location.href = wxpayUrl.value
	// 	console.log(wxpayUrl.value)
	// 	window.open(wxpayUrl.value)
	// }
	const handlePayType = (row) => {
		imgLoading.value = true
		let canvasObj = qrCodeUrl.value.querySelector('canvas');
		let imgObj = qrCodeUrl.value.querySelector('img');
		if(imgObj != null){
		  imgObj.remove()
		}
		if(canvasObj != null){
		  canvasObj.remove()
		}
		
		clearTimer()
		countNum.value = initNum.value
		payType.value = row.value
		
		
		if(row.value == wxpay.value){
			refreshWxpay()
		}else{
			refreshAlipay()
		}
		
		
		// if(row.value == wxpay.value){
		// 	payType.value = row.value
		// 	refreshQrcode()
		// }else{
		// 	ElMessage({
		// 	    message: '暂未开通支付宝支付',
		// 	    type: 'error',
		// 	})
		// 	console.log('暂未开通支付宝支付')
		// }
		
	}
	const timer = () => {
		countTimer.value = setInterval(() => {
			if(countNum.value > 0){
				countNum.value --
				getOrderStatus()
			}else{
				clearTimer()
			}
		},1000)
	}
	const clearTimer = () => {
		clearInterval(countTimer.value)
		countTimer.value = null
	}
	const showPaySuccess = () => {
		ElMessageBox.confirm(
		    '请前往游戏内查收商品',
		    '支付成功',
		    {
		      confirmButtonText: '好的',
			  showCancelButton: false,
		      type: 'success',
		    }
		  )
		    .then(() => {
				cancel()
		    })
			.catch(() => {
				cancel()
			})
		    
	}
	const getOrderStatus = () => {
		let args = {}
		if(goods.value.type){
			args.goodType = goods.value.type
		}else{
			args.goodType = 0
		}
		payStatus({ id: goods.value.orderId, ...args }).then((res) => {
			payState.value = res.data
			if(res.data == 400){//成功
				clearTimer()
				showPaySuccess()
			}else if(res.data == 900){//失败
				clearTimer()
			}else{//100待支付
				console.log('继续轮循=====')
			}
		})
	}
	const refreshWxpay = () => {
		console.log('刷新微信二维码')
		let args = {}
		if(goods.value.type){
			args.goodType = goods.value.type
		}else{
			args.goodType = 0
		}
		payWxmoney({ orderId: goods.value.orderId, goodName: goods.value.goodName, ...args }).then((res) => {//osType不传二维码
			console.log(res)
			imgLoading.value = false
			refreshImg(res.data)
			timer()
		}).catch(() => {
			imgLoading.value = false
		})
	}
	const refreshAlipay = () => {
		console.log('刷新支付宝二维码')
		let args = {}
		if(goods.value.type){
			args.goodType = goods.value.type
		}else{
			args.goodType = 0
		}
		payAlimoney({ orderId: goods.value.orderId, goodName: goods.value.goodName, osType: 99, ...args }).then((res) => {//99二维码，h5支付默认不传
			console.log(res)
			imgLoading.value = false
			refreshImg(res.data)
			timer()
		}).catch(() => {
			imgLoading.value = false
		})
	}
	const refreshImg = (url) => {
		console.log('拿图片地址')
		var qrcode = new QRCode(qrCodeUrl.value, {
			text: url,
			width: 140,
			height: 140,
			colorDark: '#000000',
			colorLight: '#ffffff',
			correctLevel: 3
		})
		showImage()
	}
	const showImage = () => {
	  let imgObj = qrCodeUrlShow.value.querySelector('img');
	  if(imgObj != null){
	    imgObj.remove()
	  }
		var mycanvas = document.getElementsByTagName('canvas')[0];
		var img = convertCanvasToImage(mycanvas);
		qrCodeUrlShow.value.append(img);
	}
	const convertCanvasToImage = (canvas) => {
		var image = new Image();
		image.src = canvas.toDataURL("image/png");
		return image;
	}
</script>

<style lang="less">
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>
	/deep/.el-dialog__body{
		padding: 10px 30px 20px;
	}
	.payBtn{
		display: inline-block;background: #07c160;color:#fff;
		width: 80%;line-height: 36px;text-align: center;margin-left: 10%;
		border-radius: 10px;
	}
	.normalBtn{
		display: inline-block;
		width: 80%;line-height: 36px;text-align: center;margin-left: 10%;
		border-radius: 10px;
	}
	.orderInfo{
		.goodPrice{
			text-align: center;color: red;font-size: 24px;
			.oprice{
				font-size: 12px;text-decoration: line-through;color: #999;margin-left: 5px;
			}
		}
		.goodTitle{
			text-align: center;margin: 10px 0;
		}
		.payTyps{
			display: flex;align-items: center;justify-content: space-around;margin: 30px 0;
			.item{
				border: 1px solid #eee;border-radius: 10px;cursor: pointer;
				width: 42%;line-height: 36px;display: inline-flex;align-items: center;justify-content: center;
				&.active{
					border: 1px solid blue;
				}
			}
		}
		.qrcodeImg{
			border: 1px solid #eee;width: 160px;height: 160px;margin: 0 auto;
			padding: 10px;box-sizing: border-box;border-radius: 10px;position: relative;
			img{
				width: 100%;
			}
			&.code400{
				&:after{
					display: block;position: absolute;left: 1px;top: 1px;
					width: 156px;height: 156px;content: '支付成功';background: rgba(255,255,255,0.96);
					line-height: 156px;text-align: center;border-radius: 10px;color: #07c160;
				}
			}
			&.code900{
				&:after{
					display: block;position: absolute;left: 1px;top: 1px;
					width: 156px;height: 156px;content: '支付失败';background: rgba(255,255,255,0.96);
					line-height: 156px;text-align: center;border-radius: 10px;color: #ee0a24;
				}
			}
		}
		.qrcodeTxt{
			text-align: center;margin: 10px 30px;font-size: 12px;
		}
	}
</style>