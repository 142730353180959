<template>
	<div class="paymentBox" :class="!isMobile() ? 'pcbox' : ''">
		<div class="payBox">
			<div class="titleTxt">
				<span>支付结果</span>
			</div>
			<!-- <div class="loading resultBox" v-if="loading">
				<van-loading size="60px" vertical>正在查询交易状态，请不要退出....</van-loading>
			</div> -->
			<template v-if="!loading">
				<div class="resultBox">
					<div class="iconBox">
						<el-icon :color="formatPaystatus(paySuccess,'color')" size="60">
							<SuccessFilled v-if="paySuccess == 400" />
							<CircleCloseFilled v-else-if="paySuccess == 900" />
							<WarningFilled v-else />
						</el-icon>
					</div>
					<p class="title">{{ formatPaystatus(paySuccess) }}</p>
					<p v-if="paySuccess == 100">是否已经支付成功？</p>
				</div>
				<div class="payResult" v-if="paySuccess == 100">
					<div class="btnBoxs">
						<div class="btnBox disabled" @click.stop="handleNoPay">
							否
						</div>
						<div class="btnBox active" @click.stop="handleIsPay">
							是
						</div>
					</div>
				</div>
				<div class="payResult" v-else>
					<div class="btnBoxs">
						<div class="btnBox disabled" @click.stop="handleCancel">
							取消
						</div>
						<div class="btnBox active" @click.stop="handleConfirm">
							确定
						</div>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script setup>
	import { getCurrentInstance,onMounted,onBeforeMount,ref,watch,computed, reactive } from 'vue'
	import { ElMessage, ElMessageBox, ElLoading } from 'element-plus'
	import { payStatus } from '@/api/pay'
	import useUserStore from '@/store/modules/user'
	import { useRoute,useRouter } from 'vue-router';
	import { isMobile } from '@/utils/common.js'
	import variables from '@/assets/css/variables.less'
	const variablesStyles = ref(variables)
	const { proxy } = getCurrentInstance();
	const router = useRouter();
	const payOrderInfo = ref({})
	onMounted(() => {
		payOrderInfo.value = router.currentRoute.value.params
		if(payOrderInfo.value.isRealPay == 0){
			paySuccess.value = 1
		}
	})
	const closeLoading = ref(false)
	const payStatusAction = ref('')
	// const isMobile = computed(() => {
	// 	return useConfigStore().isMobile
	// })
	const userSign = computed(() => {
		return useUserStore().userSign
	})
	const roleInfo = computed(() => {
		return useUserStore().roleInfo
	})
	const orderInfo = computed(() => {
		return useUserStore().orderInfo
	})
	const loading = ref(false)
	const paySuccess = ref(100)//400支付成功，900支付失败，100未知支付状态
	const payStatusList = reactive([
		{ label: '支付成功', value: 400, icon: 'success', color: variablesStyles.value.successColor },
		{ label: '支付失败', value: 900, icon: 'fail', color: variablesStyles.value.errorColor },
		{ label: '订单状态', value: 100, icon: 'underway', color: variablesStyles.value.primary }
	])
	const formatPaystatus = (val,param = 'label') => {
		let obj = payStatusList.find((item) => item.value == val)
		return obj[param] || ''
	}
	const handleIsPay = () => {
		if(loading.value){
			ElMessage({
				message: '请不要重复操作！',
				type: 'error',
			})
			// proxy.$toast('请不要重复操作！')
			return false
		}
		handleQueryOrder()
	}
	const handleNoPay = () => {
		if(loading.value){
			ElMessage({
				message: '请不要重复操作！',
				type: 'error',
			})
			// proxy.$toast('请不要重复操作！')
			return false
		}
		handleQueryOrder()
	}
	const handleQueryOrder = () => {
		loading.value = true
		const loadingEl = ElLoading.service({
		    lock: true,
		    text: '正在查询交易状态，请不要退出....',
		    background: 'rgba(0, 0, 0, 0.7)',
		})
		// setTimeout(() => {
		//     loadingEl.close()
		// 	loading.value = false
		// }, 2000)
		payStatus({ id: payOrderInfo.value.id }).then((res) => {
			paySuccess.value = res.data
			loadingEl.close()
			loading.value = false
		}).catch(() => {
			paySuccess.value = 900
			loadingEl.close()
			loading.value = false
		})
		// getOrderInfo({ orderId: payOrderInfo.value.id },orderInfo.value.qp_chanal).then((res) => {
		// 	console.log(res)
		// 	if(res.data && res.data.tradeStatus){
		// 		if(res.data.tradeStatus === "TRADE_SUCCESS" || res.data.tradeStatus === "SUCCESS"){
		// 			paySuccess.value = 1
		// 		}else{
		// 			paySuccess.value = 2
		// 		}
		// 	}else{
		// 		paySuccess.value = 2
		// 	}
		// 	loading.value = false
		// }).catch((err) => {
		// 	paySuccess.value = 2
		// 	loading.value = false
		// })
	}
	// 上报
	// const handleOrderRecord = (row) => {
	// 	let args = { channeNo: 0 },arr = ['orderid','pay_money','platformId','roleIdPublish','game_order_sn'];
	// 	Object.keys(row).forEach(key => {
	// 		if(arr.includes(key)){
	// 			if(key === 'orderid'){//安卓那边传过来的是发行角色id
	// 				args['orderId'] = row['game_order_sn']
	// 			}else if(key === 'pay_money'){
	// 				args['price'] = row[key]
	// 			}else if(key === 'roleIdPublish'){//拿到的子用户id
	// 				args['publicRoleId'] = row['roleId']
	// 			}else if(key === 'game_order_sn'){
	// 				args['gameOrderSn'] = row[key]
	// 			}
	// 		}else{
	// 			args[key] = row[key]
	// 		}
	// 	})
	// 	args.reportSource = 1;//上报来源：1前端，0安卓
	// 	savePayorder(args).then((res) => {
	// 		console.log(res)
	// 	})
	// }
	const handleConfirm = () => {
		if(closeLoading.value){
			ElMessage({
				message: '请不要重复操作！',
				type: 'error',
			})
			// proxy.$toast('请不要重复操作！')
			return false
		}
		handleClose()
	}
	const handleCancel = () => {
		if(closeLoading.value){
			ElMessage({
				message: '请不要重复操作！',
				type: 'error',
			})
			// proxy.$toast('请不要重复操作！')
			return false
		}
		handleClose()
	}
	const handleClose = () => {
		closeLoading.value = true
		// if(paySuccess.value == 1){
		// 	handleOrderRecord(orderInfo.value)
		// }
		setTimeout(() => {
			closeLoading.value = false
		},3000)
		window.location.href = '/'
		// useUserStore().clearOrder().then(() => {
		// 	payStatusAction.value = paySuccess.value == 1 ? 'pay_success' : 'pay_fail'
		// 	proxy.commonPostMsg({ action: payStatusAction.value })
		// })
	}
</script>

<style lang="less" scoped>
.paymentBox{
	position: relative;
	.resultBox{
		width: 100%;height: 135px;padding: 0 10px;box-sizing: border-box;
		position: absolute;top: 50%;left: 0;transform: translate(0,-50%);
		.iconBox{
			display: flex;align-items: center;justify-content: center;
		}
		p{
			text-align: center;font-size: 12px;color: #A1A0A0;margin: 10px 0 0;
			&.title{
				font-size: 16px;color: #212020;margin: 20px 0 0;
			}
		}
	}
}
</style>