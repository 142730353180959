<template>
	<el-dialog fullscreen :show-close="false" custom-class="adtips" v-model="visible" title="" @close="cancel">
		<div class="tipsBox">
			<div class="close" @click="cancel"></div>
			<slot></slot>
		</div>
	</el-dialog>
</template>

<script setup>
	import { getCurrentInstance, reactive, ref, onMounted, defineProps, defineEmits, computed } from 'vue'
	import { isMobile } from '@/utils/common.js'
	
	const { proxy } = getCurrentInstance();
	const props = defineProps({
		showVisible: {
			type: Boolean,
			default: false
		},
		showTitle: {
			type: String,
			default: ''
		}
	})
	const emits = defineEmits(['cancel'])
	const cancel = () => {
		emits('cancel')
	}
	const visible = computed(() => {
		return props.showVisible
	})
	const title = computed(() => {
		return props.showTitle
	})
	
</script>

<style lang="less">
	.adtips{
		background: transparent!important;position: relative;
		.tipsBox{
			width: 26rem;height: 18rem;padding: 1rem;box-sizing: border-box;
			background: url('/iaa/popupBgs.png') no-repeat center;
			background-size: 100%;color: #FE551F;
			position: absolute;left: 53%;top: calc(50% - var(--el-dialog-padding-primary)*2 - 10px);transform: translate(-50%,-50%);
			.close{
				width: 2rem;height: 2rem;
				background: url('/iaa/popupCloses.png') no-repeat center;
				background-size: 100%;
				position: absolute;top: -1.5rem;right: -1rem;
			}
		}
	}
</style>