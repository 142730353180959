import { parseTime,mergeRecursive,filterHTML,commonPostMsg,isJsonString } from '@/utils/common'
import { Partten } from "@/utils/partten";
import { Base64 } from "js-base64";
import cache from './cache'

export default {
	install: (app) => {
		app.config.globalProperties.$cache = cache
		app.config.globalProperties.partten = Partten
		app.config.globalProperties.parseTime = parseTime
		app.config.globalProperties.mergeRecursive = mergeRecursive
		app.config.globalProperties.filterHTML = filterHTML
		app.config.globalProperties.commonPostMsg = commonPostMsg
		app.config.globalProperties.$Base64 = Base64
		app.config.globalProperties.isJsonString = isJsonString
	}
}