import cache from '@/plugins/cache'
import { defineStore } from 'pinia'

const useIaaStore = defineStore(
  'iaa',
  {
    state: () => ({
		isTest: cache.session.get('iaa-istest') ? cache.session.get('iaa-istest') : '',
		secretParams: cache.session.get('iaa-secret-params') ? cache.session.get('iaa-secret-params') : '',
    }),
    actions: {
		clearSecretParams(){
			return new Promise((resolve, reject) => {
				this.secretParams = ''
				cache.session.remove('iaa-secret-params');
				resolve()
			})
		},
		setSecretParams(val){
			return new Promise((resolve, reject) => {
				this.secretParams = val
				cache.session.set('iaa-secret-params', val);
				resolve()
			})
		},
		setIsTest(val){
			return new Promise((resolve, reject) => {
				this.isTest = val
				cache.session.set('iaa-istest', val);
				resolve()
			})
		}
    }
  })
  
export default useIaaStore
