<template>
	<div class="subMain">
		<router-view :key="key" />
	</div>
</template>

<script setup>
	import { ref, computed } from 'vue'
	import { useRoute } from 'vue-router'
	// import { ArrowRight,ArrowRightBold } from '@element-plus/icons-vue'
	// import Banner from '@/components/banner/Index.vue'
	const router = useRoute();
	// const active = ref(router.path);
	// const activeTitle = ref(router.meta.title);
	const key = computed(() => {
		return router.fullPath
	})
</script>
<style lang="less">
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>

</style>
