<template>
	<Mydialog :showVisible="showSubLists" :showTitle="`选择小号`" @cancel="handleClose">
	<!-- <el-dialog class="loginTabs" v-model="showSubLists" title="选择小号" :width="isMobile()?`95%`:`500`" @close="handleClose"> -->
		<div v-loading="loading">
			<el-collapse v-model="activeNames" @change="handleChange" accordion v-if="subList.length > 0">
				<template v-for="(item,index) in subList" :key="index">
				  <el-collapse-item :title="`小号${index+1}：${item.sonId}_${item.sonNickName}`" :name="item.sonId">
					<div>
					  
						<template v-if="roleList.length > 0">
							<el-descriptions border :column="isMobile()?1:2" v-for="(i,j) in roleList" :key="j">
								  <el-descriptions-item label="角色ID：" :span="isMobile()?1:2">{{ i.roleId}}
									<el-button style="float: right;" key="plain" type="primary" link :icon="Switch" @click.stop="confirm(i)">
										选择角色
									  </el-button>
								  </el-descriptions-item>
								  <el-descriptions-item label="角色名称：">{{ i.roleName }}</el-descriptions-item>
								  <el-descriptions-item label="区服名称：">{{ i.serviceName}}</el-descriptions-item>
							</el-descriptions>
						</template>
						<el-link v-else :underline="false" type="danger">暂无角色</el-link>
					</div>
				  </el-collapse-item>
				</template>			  
			</el-collapse>
			<div v-else><p style="text-align: center;font-size: 14px;">暂无小号</p></div>
		</div>
	<!-- </el-dialog> -->
	</Mydialog>
</template>

<script setup>
	import Mydialog from '@/components/mydialog'
	import { getCurrentInstance, reactive, ref, onMounted, defineProps, defineEmits, computed } from 'vue'
	import { Switch } from '@element-plus/icons-vue'
	import { getSubUser, getSubUserRole } from '@/api/user'
	import useUserStore from '@/store/modules/user'
	import { isMobile } from '@/utils/common.js'
	const { proxy } = getCurrentInstance();
	const props = defineProps({
		showSubList: {
			type: Boolean,
			default: false
		},
		gameid: {
			type: Number,
			default: 0
		}
	})
	const emits = defineEmits(['changeSubUser'])
	const showSubLists = computed(() => {
		return props.showSubList
	})
	const gameId = computed(() => {
		return props.gameid
	})
	const subList = ref([])
	const roleList = ref([])
	const activeNames = ref('')
	const loading = ref(false)
	const handleChange = (val) => {
	  console.log(val)
	  search({ sonId: val })
	}
	const search = (obj) => {
		getSubUserRole({ sonId:obj.sonId, gameId:gameId.value }).then((res) => {
			console.log(res)
			roleList.value = res.data
			loading.value = false
		}).catch(() => {
			loading.value = false
		})
	}
	const confirm = (obj) => {
		useUserStore().setRoleInfo(obj).then(() => {
			emits('changeSubUser',obj)
		})
	}
	const handleClose = () => {
		emits('changeSubUser',{})
	}
	onMounted(() => {
		loading.value = true
		getSubUser({}).then((res) => {
			console.log(res)
			subList.value = res.data
			activeNames.value = subList.value[0].sonId
			handleChange(activeNames.value)
		}).catch(() => {
			loading.value = false
		})
	})
</script>

<style lang="less">
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>
	.el-descriptions + .el-descriptions{
		margin-top: 10px;
	}
</style>