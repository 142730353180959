import request from "@/utils/request";
import CryptoJS from 'crypto-js'
import { decryptByDES } from "@/utils/3DES";
import useIaaStore from '@/store/modules/iaaParams'
import { ElMessage } from 'element-plus'

export default {
	sdkPostMsg: function(params){
		if(useIaaStore().isTest == 1){
			console.log('web端测试======')
			if(params.action === 'webDidLoad'){
				let secrets = 'jTspipVJ7lM+My9d9O62tt4C8euvB8W3v8CKYoZqlB61NbsVHW0ABLdVjTw44DzaModwa/4XPjZCZUr4jy5nsiy09Qpg3dD/VZbT8hMtv7SUPQWOAcTMICS20RRfnagD1kpAbCNrkkEAObbtcg4sZKjLKN0AfW4sNhkeTxPUlCIY6KJ6Ni7IumdmY8ws1R1zrsAX8W7KsDjZHSc2XIWJEx0Ihh4kJ5Kj9eO+lFkVBoEt3kH8Q3r9ISZKHD9ZnGmOstK5mzsqPwXhmntBJdTUjmYg+YOuRDp3V33KxRJG+yasbKDbUdiIi/1A85Ahs5VfXHX+9H97SZMxx4NJ/SMkCtDyDuHoKoxee4xPF7y4arQlnSN5IfjjVubYE6HlHlgpwElS7e0SR0yot9zzwWF5A3Wdr4u8P11MxvLOboiv6pDFS8S3q+kPGA+yYczNr5RQD+Ieam5r/FsHjJUPo5xkr1cEjdmy/Qh0s4ZGEIR2JvU+KQvfQj+jWXBUh8gV5aP3clqEQ94/WnCdDIqW5kxbB9Px60Dcc6BmOU/Ef4eVe8I+C+Cxl4QIsZT6HBru1xyv1erBW4/3389hh6hjIBQxOyNRVNUy97zlsq4Ua8tEv72tmLBFZuUfa8XR4hYFxlEulLUI0pRo5i4+qETrcmLiuhS6N08TAUS3BZ6UPGi3ybiU/BEBU+GWT+BvC08B1PHv2nANllBXMwbVqt1P/9d4o9TclVnRG8tWThxSk2iGp8/Jf0rk+zgJ97vuqX95sK0rd/XkpedwB2UzBQ2kimhj8bQOvdeIVs82F4mrTRtv9qqqnDgPqDo7V8A4CirvnacpKvmWOdGn+7Oh5Zf5UFY4l7capJqtkSZLkzsgaR1BZk1+QwARLFXQNNFUEtcu42UrKiSRGaVLf2iGC+ZhzdtCONVvDD3jMf5GHGcAMxqM440nw7tvlyj6ola/9UlK8uM+7VFVMoXnpTzv2budlV8jCnhc3TNvtzp3xB/xzYoKSnM='
				// let secrets = 'jTspipVJ7lOQd/H7Z2bIutKcvcPJUC9QlSC2LlfhjA5V9WhQNoNwLJ4Pk5GKFj02YnktHaWwsJOWUyLswuTI9RrH1hJ5XXERudLJ5+udVFB1iO8It5FS7UwB+JPdzlaiOMEbDwyEC/H130evEmEnD5U+kminn7IaEQ+OHATvRwI/MQePwjsMWefBA8Sv0azw2BuavCg+G/WqLCBFovS50vM4p7SA16GXDH4Ip5iKrsLhAJkI/3UPuVt34/IjZrw2G1pSk1HtZo2Akxxl/V9WXVKs2aUhV5vUHzMmsi5GPabm3IOkhFpl/s+E7NyFXa1znXrwC4jvjpHPXenlMWfgHltaU5WUp7XcwBtnOe/h44dsIa83L7SSVg=='
				useIaaStore().setSecretParams(secrets)
			}else{
				console.log('调用web端:'+JSON.stringify(params))
			}
		}else{
			var u = navigator.userAgent;
			var isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
			var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
			if(isIos){
				console.log('调用苹果端:'+JSON.stringify(params))
				iaaIosPostMsg(params)
			}else if(isAndroid){
				console.log('调用安卓端:'+JSON.stringify(params))
				iaaAndroidPostMsg(params)
			}
		}
	},
	sdkDialogInfo: function(dialogCallback,errCallback){
		let args = getparams()
		let sign = getSign(args)
		delete args.ScretKeys
		request({
			url: '/user/viewInfo',
			headers: {
			  isIaaApi: true
			},
			method: 'post',
			data: { sign, ...args }
		}).then(function (res) {
			if(res.code == 200){
				dialogCallback(res.data)
			}else{
				errCallback(res)
				ElMessage({
				    message: res.msg,
				    type: 'error',
					plain: true
				})
			}
		})
	},
	sdkDialogRewardInfo: function(dialogCallback,errCallback){
		let args = getparams()
		let sign = getSign(args)
		delete args.ScretKeys
		request({
			url: '/turntableConfig/getInfo',
			headers: {
			  isIaaApi: true
			},
			method: 'post',
			data: { sign, ...args }
		}).then(function (res) {
			if(res.code == 200){
				dialogCallback(res.data)
			}else{
				errCallback(res)
				ElMessage({
				    message: res.msg,
				    type: 'error',
					plain: true
				})
			}
		})
	},
	sdkRewardGetKeys: function(id,getKeysCallback){
		let args = getparams()
		let sign = getSign(args)
		delete args.ScretKeys
		request({
			url: `/turntableConfig/exchangeProp/${id}`,
			headers: {
			  isIaaApi: true
			},
			method: 'post',
			data: { sign, ...args }
		}).then(function (res) {
			if(res.code == 200){
				getKeysCallback(res.data)
			}else{
				ElMessage({
				    message: res.msg,
				    type: 'error',
					plain: true
				})
			}
		})
	},
	sdkRewardGetProp: function(num,getPropCallback){
		let args = getparams(true)
		let sign = getSign(args)
		delete args.ScretKeys
		request({
			url: `/turntableConfig/getProp/${num}`,
			headers: {
			  isIaaApi: true
			},
			method: 'post',
			data: { sign, ...args }
		}).then(function (res) {
			if(res.code == 200){
				getPropCallback(res.data)
			}else{
				ElMessage({
				    message: res.msg,
				    type: 'error',
					plain: true
				})
			}
		})
	},
    sdkVideoAd: function (adCallback,callback) {
		let args = getparams(true)
		let sign = getSign(args)
		delete args.ScretKeys
		request({
			url: '/order/create/android',
			headers: {
			  isIaaApi: true
			},
			method: 'post',
			data: { sign, ...args }
		}).then(function (res) {//code：201开启倒计时，202免广告卡
			if(res.code == 200){
				adCallback(res.data)
			}else if(res.code == 201 || res.code == 202){
				callback(res)
				ElMessage({
					message: res.msg,
					plain: true
				})
			}else{
				callback(res)
				ElMessage({
					message: res.msg,
					type: 'error',
					plain: true
				})
			}
			
		});
    },
	sdkVideoAdKeys: function (adCallback,callback) {
		let args = getparams(true)
		let sign = getSign(args)
		delete args.ScretKeys
		request({
			url: '/turntableConfig/createOrder',
			headers: {
			  isIaaApi: true
			},
			method: 'post',
			data: { sign, ...args }
		}).then(function (res) {//code：201开启倒计时
			if(res.code == 200){
				adCallback(res.data)
			}else if(res.code == 201){
				callback(res)
				ElMessage({
					message: res.msg,
					plain: true
				})
			}else{
				callback(res)
				ElMessage({
					message: res.msg,
					type: 'error',
					plain: true
				})
			}
			
		});
	},
    
  };
	function getparams(isExtend=false){
		let params = decryptByDES(useIaaStore().secretParams)
		let args = JSON.parse(params),obj = {};
		for(let [key, value] of Object.entries(args)){
		  if (typeof value !== 'undefined' && value !== "") {
			  if(!isExtend){
				  if(key != "extendParams"){
					  obj[key] = value
				  }
			  }else{
				  obj[key] = value
			  }
		  }
		}
		return obj
	}
 function iaaIosPostMsg (obj) {
  	let backReturn = window.webkit.messageHandlers.eventComplete.postMessage(JSON.stringify(obj));
  	return backReturn;
  }
 function iaaAndroidPostMsg (obj) {
  	let backReturn = sdkcnadsapi.callAndroidFunction(JSON.stringify(obj));
  	return backReturn;
  }	

  function getSign(paramData) {
	var formArr = [];
	 for(let [i, value] of Object.entries(paramData)){
	  if (typeof value !== 'undefined' && i != "ScretKeys" && value !== "") {
		var str = "" + i + "=" + value + "";
		formArr.push(str);
	  }
	}
	var signStr = formArr.sort().join("&") + "&" + paramData.ScretKeys;
	console.log(signStr)
	let sign = CryptoJS.MD5(signStr).toString();
	console.log(sign)
	return sign;
  }

