<template>
	<div class="navbarBox" :class="{'pc':!isMobile(),'m':isMobile()}">
		<div class="tabnav maxBox">
			<div class="logoImg">
				<router-link to="/">
					<img class="logoIcon" src="@/assets/logo.png" />
					奇葩游戏官方充值中心
				</router-link>
			</div>
			<div class="tabBtn">
				<span class="menuBtn" v-if="!showMenu" @click="showMenu = true">
					<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
					  <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
					</svg>
				</span>
				<span class="closeBtn" v-else @click="showMenu = false">
					<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
					  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
					</svg>
				</span>
			</div>
			<div class="tablist" v-if="showMenu || !isMobile()">
				<ul>
					<!-- <li :class="{'active':current=='/index'}">
						<router-link to="/index">首页</router-link>
					</li>
					<li :class="{'active':current=='/products'}">
						<router-link to="/products">游戏列表</router-link>
					</li> -->
					<template v-if="isMobile()">
						<li :class="{'active':current=='/index'}">
							<router-link to="/index">首页</router-link>
						</li>
						<li v-if="userToken">
							<a href="javascript:;" @click="handleLogout">退出登录</a>
						</li>
						<li>
							<a href="javascript:;" @click="handleReload">刷新</a>
						</li>
						<!-- <li v-if="iswxBrowser()">
							<a href="javascript:;" @click="handleTest">测试</a>
						</li> -->
					</template>
				</ul>
			</div>
			<div class="navIcons" v-if="!isMobile()">
				<!-- <template v-if="!userid">
					<router-link class="l" to="/account/login">登录</router-link>
					<router-link class="l" to="/account/register">注册</router-link>
				</template> -->
				<template v-if="userToken">
					<span class="i">
						<el-icon>
							<User />
						</el-icon>
					</span>
					<a href="javascript:;" class="l">{{ userName }}</a>
					<a href="javascript:;" class="l" @click="handleLogout">退出登录</a>
				</template>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { User,Search,ShoppingCart } from '@element-plus/icons-vue'
	import { isMobile, wxcode, iswxBrowser } from '@/utils/common.js'
	import { ref,defineAsyncComponent,onMounted,getCurrentInstance, computed,watch,inject } from 'vue'
	import { useRoute,useRouter,onBeforeRouteUpdate } from 'vue-router'
	import useUserStore from '@/store/modules/user'
	
	const { proxy } = getCurrentInstance();
	const route = useRoute();
	const router = useRouter();
	const current = ref(route.meta.activeMenu);
	const showMenu = ref(false);
	const reload = inject('reload');
	onBeforeRouteUpdate((to) => {
		current.value = to.meta.activeMenu;
		showMenu.value = false;
	});
	// const userid = computed(() => {
	// 	return useUserStore().userid
	// })
	const userToken = ref('')
	const userSign = computed(() => {
		return useUserStore().token || ''
	})
	const userName = ref('')
	const name = computed(() => {
		return useUserStore().name || ''
	})
	const handleLogout = () => {
		useUserStore().logOut().then(() => {
			// router.push('/index')
			location.reload()
		})
	}
	const handleReload = () => {
		location.reload()
	}
	const handleTest = () => {
		wxcode()
	}
	watch(userSign, (newVal, oldVal) => {
		if(newVal){
			userToken.value = newVal
		}else{
			userToken.value = ''
		}
	},{ immediate: true })
	watch(name, (newVal, oldVal) => {
		if(newVal){
			if(newVal.length == 11){
				userName.value = newVal.replace(/(\d{3})\d{5}(\d{3})/, '$1*****$2')
			}else{
				userName.value = newVal
			}
		}else{
			userName.value = ''
		}
	},{ immediate: true })
</script>

<style lang="less" scoped>
	.navbarBox{
		width: 100%;background: #FFFFFF;border-bottom: 0.1rem solid rgba(18,18,18,0.08);
		.tabnav{
			width: 100%;height: 80px;
			display: grid;padding: 20px 0;box-sizing: border-box;
		}
		&.pc{
			.maxBox{
				max-width: 1200px;margin: 0 auto;
				display: grid;grid-template-columns:auto auto 1fr;column-gap:2rem;align-items: center;
				.tabBtn{
					display: none;
				}
				.tablist{
					ul{
						display: inline-flex;height: 100%;
						li{
							cursor: pointer;color: #292929;font-size: 1.4rem;
							height: 100%;overflow: hidden;
							a{
								display: inline-block;color: rgba(18,18,18,0.75);
								padding: 1.2rem;
							}
							&:hover,&.active{
								a{
									color: rgb(18,18,18);transition: 0.3s;
									text-decoration: underline;text-underline-offset: 0.3rem;
								}
							}
						}
						
					}
				}
				.navIcons{
					display: flex;justify-self: end;align-items: center;
					span.i{
						display: inline-flex;width: 4.4rem;height: 4.4rem;
						align-items: center;justify-content: center;
						color: rgba(18,18,18,0.75);cursor: pointer;
						/deep/ .el-icon{
							font-size: 22px;
						}
						&:hover,&.active{
							color: rgb(18,18,18);transition: 0.3s;
							text-decoration: underline;text-underline-offset: 0.3rem;
							/deep/ .el-icon{
								font-size: 24px;
							}
						}
					}
					a.l{
						display: inline-flex;padding: 0 1rem;height: 1.5rem;
						align-items: center;justify-content: center;font-size: 1.5rem;
						color: rgba(18,18,18,0.75);cursor: pointer;
						&:hover{
							color: rgb(18,18,18);transition: 0.3s;
							text-decoration: underline;text-underline-offset: 0.3rem;
						}
						&:last-child{
							border-left: 1px solid rgba(18,18,18,0.3);
						}
					}
					
				}
				.logoImg{
					height: 100%;width: 260px;position: relative;
					a{
						display: inline-block;font-size: 18px;margin-top: 0;font-weight: 500;color: #2d2d2d;
						padding: 0;box-sizing: border-box;padding-left: 55px;width: 100%;
						position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);
						.logoIcon{
							position: absolute;left: 0;top: 50%;transform: translate(0%,-50%);
						}
					}
					img{
						max-width: 40px;margin-right: 10px;
					}
				}
			}
		}
		&.m{
			.tabnav{
				width: 100%;height: 65px;padding: 16px 0;
			}
			.maxBox{
				position: relative;
				.tabBtn{
					position: absolute;top: 20px;right: 20px;
					.menuBtn,.closeBtn{
						display: inline-block;width: 100%;height: 100%;
					}
				}
				.tablist{
					position: fixed;z-index: 1111;background: #fff;
					top: 65px;left: 0;width: 100%;height: calc(100vh - 65px);
					ul{
						width: 100%;
						li{
							width: 100%;padding: 20px 0;text-align: center;
							border-bottom: 1px solid #f5f5f5;color: #303030;font-size: 14px;
							a{
								display: inline-block;color: #292929;
							}
							&.active,&:hover{
								a{
									color: #ff5a00;transition: 0.3s;
								}
							}
							&:first-child{
								border-top: 1px solid #f5f5f5;
							}
						}
					}
				}
				.logoImg{
					width: 240px;height: 100%;
					a{
						display: inline-flex;font-size: 16px;height: 100%;font-weight: 500;color: #2d2d2d;
						align-items: center;letter-spacing: 0.05em;
					}
					img{
						max-width: 30px;margin-left: 16px;margin-top: 0;margin-right: 8px;
						// max-width: 100%;
					}
				}
				.navIcons{display: none;}
			}
		}
	}
</style>
