<template>
	<Mydialog :showVisible="showLogins" :showTitle="`登录`" @cancel="loginSuccess">
	<!-- <el-dialog class="loginTabs" v-model="showLogins" title="" :width="isMobile()?`95%`:`500`" @close="loginSuccess"> -->
		<div class="loginCon" v-if="isMobile()">
			<LoginTel v-if="activeName == 'telLogin'" :gameid="gameId" @loginSuccess="loginSuccess" />
			<Login v-if="activeName == 'login'" :gameid="gameId" @loginSuccess="loginSuccess" />
			<div class="footer">
				<a href="javascript:;" :class="{'active':activeName == 'telLogin'}" @click="activeName = 'telLogin'">短信验证登录</a>
				<a href="javascript:;" :class="{'active':activeName == 'login'}" @click="activeName = 'login'">账号密码登录</a>
				<!-- <a href="javascript:;">遇见问题</a> -->
			</div>
		</div>
	    <el-tabs v-model="activeName" v-else>
	        <el-tab-pane label="短信登录" name="telLogin">
				<LoginTel :gameid="gameId" @loginSuccess="loginSuccess" />
			</el-tab-pane>
	        <el-tab-pane label="密码登录" name="login">
				<Login :gameid="gameId" @loginSuccess="loginSuccess" />
			</el-tab-pane>
		</el-tabs>
	<!-- </el-dialog> -->
	</Mydialog>
</template>

<script setup>
	import Mydialog from '@/components/mydialog'
	import { getCurrentInstance, reactive, ref, onMounted, defineProps, defineEmits, computed } from 'vue'
	import Login from '@/views/account/login'
	import LoginTel from '@/views/account/loginTel'
	import { isMobile } from '@/utils/common.js'
	const { proxy } = getCurrentInstance();
	const props = defineProps({
		showLogin: {
			type: Boolean,
			default: false
		},
		gameid: {
			type: Number,
			default: 0
		}
	})
	const emits = defineEmits(['loginSuccess'])
	const showLogins = computed(() => {
		return props.showLogin
	})
	const gameId = computed(() => {
		return props.gameid
	})
	const loginVisible = ref(false)
	const activeName = ref('telLogin')
	const loginSuccess = (val={}) => {
		emits('loginSuccess',val)
	}
</script>

<style lang="less">
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>
	/deep/ .el-tabs__nav-scroll{
		display: flex;align-items: center;justify-content: center;
	} 
	/deep/ .el-tabs__item{
		padding: 0 40px;
		font-size: 16px;
	}
	/deep/ .el-tabs__content{
		padding: 0 16px;
	}
	.loginCon{
		padding: 0 16px;
		.footer{
			font-size: 14px;display: flex;align-items: center;justify-content: space-between;
			a{
				display: inline-block;
				&.active{
					color: #00c3ff;
				}
			}
		}
	}
</style>