<template>
	<div class="maxBox">
		<div class="loginBox">
			<el-form
				:label-position="labelPosition"
				label-width="100px"
				:model="form"
				style="max-width: 446px"
			  >
				<el-form-item label="手机号">
				  <el-input v-model="form.phone" placeholder="请输入手机号" />
				</el-form-item>
				<el-form-item label="验证码">
				  <el-input v-model="form.code" placeholder="请输入短信验证码" >
					  <template #append>
						  <el-button @click="getCode" :disabled="codeDisable">
							  <span v-if="codeDisable && countNum > 0">{{ countNum }}s后重发</span>
							  <span v-else>获取验证码</span>
						  </el-button>
					  </template>
				  </el-input>
				</el-form-item>
				
				<el-form-item class="agreeBox">
					<el-checkbox v-model="checkedAgree" label="" size="large" />
					<div class="labels">已阅读或同意
				  <el-link type="primary" href="/policies/user">《奇葩葩用户协议》</el-link>
				  <el-link type="primary" href="/policies/privacy">《奇葩葩隐私政策》</el-link>
				  ，未注册的手机号验证通过将自动注册</div>
				</el-form-item>
				<el-form-item>
				  <el-button style="width: 100%;height: 47px;font-size: 16px;margin-top: 30px;" 
					  type="primary" 
					  @click="submitForm"
				  >登录
				  </el-button>
				</el-form-item>
			  </el-form>
			  
		  </div>
	</div>
</template>

<script setup>
	import { ElMessage, ElMessageBox } from 'element-plus'
	import { reactive, ref, onMounted, defineProps, defineEmits, computed } from 'vue'
	import { useRouter } from 'vue-router'
	import { telLogin,getCodeSms } from '@/api/user'
	import useUserStore from '@/store/modules/user'
	
	let router = useRouter();
	const labelPosition = ref('top')
	const checkedAgree = ref(false)
	const form = reactive({
	  phone: '',
	  code: '',
	  gameId: ''
	})
	const emits = defineEmits(['loginSuccess'])
	const props = defineProps({
		gameid: {
			type: Number,
			default: 0
		}
	})
	const gameId = computed(() => {
		return props.gameid
	})
	const countNum = ref(60)
	const countTimer = ref(null)
	const codeDisable = ref(false)
	onMounted(()=>{
	})
	const getCode = () => {
		if(countTimer.value){
			return false
		}
		if(!form.phone){
			ElMessage({
			    message: '请先输入手机号',
			    type: 'error',
			})
			return false
		}
		// timer()
        getCodeSms(form.phone).then(res => {
          console.log(res)
		  timer()
        });
	}
	const timer = () => {
		countTimer.value = setInterval(() => {
			if(countNum.value > 0){
				countNum.value --
				codeDisable.value = true
			}else{
				clearCount()
			}
		},1000)
	}
	const submitForm = () => {
		if(!form.phone){
			ElMessage({
			    message: '请先输入手机号',
			    type: 'error',
			})
			return false
		}
		if(!form.code){
			ElMessage({
			    message: '请先输入验证码',
			    type: 'error',
			})
			return false
		}
		if(checkedAgree.value){
			form.gameId = gameId.value
			telLogin(form).then((res) => {
				console.log(res)
				useUserStore().setInfo(res.data).then(() => {
					emits('loginSuccess',res.data)
				})
			}).catch((err) => {
				console.log(err)
				reset()
			})
		}else{
			ElMessage({
			    message: '请先同意用户协议和隐私政策',
			    type: 'error',
			})
		}
		
	}
	const reset = () => {
		form.phone = ''
		form.code = ''
	}
	const clearCount = () => {
		clearInterval(countTimer.value)
		countTimer.value = null
		countNum.value = 60
		codeDisable.value = false
	}
</script>
<style lang="less">
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>
	.maxBox{flex-wrap: wrap;}
	h1{width: 100%;}
	.loginBox{
		max-width: 446px;margin: 0 auto;width: 100%;
		.login-code {
		  width: 115px;height: 43px;float: right;
		  img {
		    cursor: pointer;
		    vertical-align: middle;
		  }
		}
		.login-code-img {
		  height: 43px;
		}
		.agreeBox{
			/deep/.el-form-item__content{
				display: inline-flex;align-items: flex-start;justify-content: flex-start;flex-wrap: nowrap;
				.el-checkbox.el-checkbox--large{
					height: 18px;margin-right: 5px;
					// align-items: baseline;
				}
			}
			.labels{
				line-height: 18px;font-size: 12px;color: #999;display: inline-block;
				a{ font-size: 12px; }
			}
		}
	}
	.otherLogin{
		width: 100%;margin-top: 40px;display: flex;justify-content: space-between;
		a{
			display: inline-flex;align-items: center;justify-content: center;
			box-sizing: border-box;border-radius: 25px;
			width: 49%;padding: 12px 10px;color: rgba(18,18,18,0.75);
			border: 1px solid rgba(18,18,18,0.75);font-size: 15px;cursor: pointer;
			transition: 0.3s;
			span{
				margin-left: 12px;
			}
			&:hover{
				background: rgb(18,18,18);color: #fff;
			}
		}
	}
</style>
