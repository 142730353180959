<template>
  <div class="section1">
      <div class="main">
		  <div class="coinBox">
			  <div class="back" @click="handleBack">
				  <el-icon style="margin-right: 3px;"><ArrowLeftBold /></el-icon>
				  积分明细<span>（仅显示近半年记录）</span>
			  </div>
			  <span class="coins">当前积分：</span>{{ points }}
		  </div>
		  <div class="content" v-if="dataList.length == 0">
			  <p class="emps">暂无记录</p>
		  </div>
          <div class="content" v-else>
			  <ul>
				  <li v-for="(item,index) in dataList" :key="index">
					  <div class="imgBox" v-if="item.goodIcon">
						  <img :src="item.goodIcon" />
					  </div>
					  <div class="details">
						  <p class="title">{{ item.goodName }} * {{ item.propNum }}</p>
						  <p>{{ item.gameName }}</p>
						  <p>{{ parseTime(item.addTime) }}</p>
					  </div>
					  <div class="conis">{{ formatSymbol(item.type) }} {{ item.money }}<span>积分</span></div>
				  </li>
			  </ul>
			  <div class="pageBox">
				<el-pagination v-if="total > queryParams.pageSize"
			      size="small"
			      background
			      layout="prev, pager, next"
				  v-model:current-page="queryParams.pageNum"
			      :total="total"
				  :page-size="queryParams.pageSize"
				  :current-page="queryParams.pageNum"
				  @prev-click="handleQuery"
				  @next-click="handleQuery"
				  @current-change="handleQuery"
			    />
				</div>
          </div>
      </div>
  </div>
</template>

<script setup>
	import { ref, onMounted, computed, watch, inject, reactive } from "vue";
	import { useRouter,useRoute } from 'vue-router'
	import useUserStore from '@/store/modules/user'
	import { pointList } from '@/api/point'
	const router = useRouter();
	const handleBack = () => {
		router.back()
	}
	const userid = computed(() => {
		return useUserStore().userid || ''
	})
	const user = computed(() => {
		return useUserStore().userInfo || {}
	})
	const points = computed(() => {
		return useUserStore().points || 0
	})
	const dataList = ref([])
	const total = ref(0)
	const queryParams = reactive({
		pageSize: 10,
		pageNum: 1
	})
	onMounted(() => {
		getList()
	})
	const formatSymbol = (val) => {
		if(val == 1){
			return '+'
		}else{
			return '-'
		}
	}
	const getList = () => {
		if(!userid.value){
			handleBack()
			return false
		}
		pointList({ userId:userid.value, ...queryParams }).then((res) => {
			console.log(res)
			dataList.value = res.rows
			total.value = res.total
		})
	}
	const handleQuery = (cur) => {
		queryParams.pageNum = cur
		getList()
	}
</script>
<style lang="less">
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>
.main{
	max-width: 1200px;margin: 0 auto;
}
.pageBox{
	margin-top: 10px;display: flex;justify-content: center;
}
.coinBox{
	padding: 40px 16px 10px;width: 100%;box-sizing: border-box;
	text-align: right;background: #00c3ff;color: #fff;
	font-size: 16px;position: relative;
	span.coins{
		font-size: 12px;
	}
	.back{
		position: absolute;left: 16px;top: 10px;font-size: 14px;
		display: flex;align-items: center;cursor: pointer;
		span{
			font-size: 10px;color: rgba(255,255,255,0.9);
		}
	}
}
.content{
	.emps{
		text-align: center;font-size: 14px;color: #ccc;margin-top: 50px;
	}
	width: 100%;font-size: 14px;
	ul{
		padding: 0 17px;
		li{
			display: flex;align-items: center;justify-content: space-between;
			padding: 12px 0;
			.imgBox{
				width: 62px;height: 62px;margin-right: 10px;
				img{
					width: 100%;
				}
			}
			.details{
				flex: 1;
				p{
					font-size: 12px;color: #ccc;line-height: 18px;
				}
				.title{
					font-size: 14px;color: #2d2d2d;letter-spacing: 1px;margin: 3px 0;
				}
			}
			.conis{
				font-size: 16px;color: #00c3ff;
				span{
					font-size: 12px;display: inline-block;margin-left: 5px;
				}
			}
		}
		li + li{
			border-top: 1px solid #eee;
		}
	}
}
</style>
