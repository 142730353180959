<template>
	<el-config-provider :locale="locale">
		<router-view v-if="isRouterActive"/>
	</el-config-provider>
</template>
<script setup>
import { ref, provide, nextTick, onMounted, onBeforeMount, getCurrentInstance } from "vue";
import { isMobile,iswxBrowser,wxcode } from '@/utils/common.js'
import useUserStore from '@/store/modules/user'
import useIaaStore from '@/store/modules/iaaParams'
import zhCn from "element-plus/lib/locale/lang/zh-cn";
const locale = ref(zhCn);
const isRouterActive = ref(true)
const { proxy } = getCurrentInstance(); 
provide('reload', () => {
	isRouterActive.value = false
	nextTick(() => {
	    isRouterActive.value = true
	})
})
onMounted(() => {
	window.onload = () => {
		window.addEventListener('message', function (e) {
			if(e && e.data != 'undefined' && proxy.isJsonString(e.data)){
				let datas = JSON.parse(e.data);
				if(datas.key == listenKey.value){
					let args = Object.assign({},datas)
					args.action = datas.func
					handleSwitch(args);
				}
			}
		}, true)
	}
})
onBeforeMount(()=>{
	window.CallbackFromIOS = CallbackFromIOS;
	window.CallbackFromAndroid = CallbackFromAndroid;
})
const CallbackFromIOS = (str) => {
	let jsonStr = proxy.$Base64.decode(str);
	let obj = JSON.parse(jsonStr);
	handleSwitch(obj);
}
const CallbackFromAndroid = (str) => {
	let jsonStr = proxy.$Base64.decode(str);
	let obj = JSON.parse(jsonStr);
	handleSwitch(obj);
}
const handleSwitch = (obj) => {
	console.log(obj)
	switch(obj.action){
		case 'getSuperUserInfo':
			useIaaStore().setSecretParams(obj.data)
		break;
	default:
		break;
	}
}
</script>
<style lang="less">
html{
	font-family: Assistant, sans-serif;
	font-size: calc(20 * 1rem / 32);
	color: #121212;
	-webkit-text-size-adjust: 100%;
	zoom: 1;
	-moz-transform: scale(1);
	-moz-transform-origin: 0 0;
	-o-transform: scale(1);
	-o-transform-origin: 0 0;
	-webkit-transform: scale(1);
	-webkit-transform-origin: 0 0;
}
</style>
