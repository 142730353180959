<template>
	<div class="maxBox">
		<div class="loginBox">
			<h1>注册账号</h1>
			<el-form
				:label-position="labelPosition"
				label-width="100px"
				:model="form"
				style="width: 446px"
			  >
				<el-form-item label="账号">
				  <el-input v-model="form.userName" />
				</el-form-item>
				<el-form-item label="昵称">
				  <el-input v-model="form.nickName" />
				</el-form-item>
				<el-form-item label="邮箱">
				  <el-input v-model="form.email" />
				</el-form-item>
				<el-form-item label="性别">
					<el-radio-group v-model="form.sex" size="large" style="width: 100%;">
					  <el-radio-button label="0" >男</el-radio-button>
					  <el-radio-button label="1" >女</el-radio-button>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="密码">
				  <el-input type="password" v-model="form.password" />
				</el-form-item>
				<el-form-item label="确认密码">
				  <el-input type="password" v-model="comfirmPassword" />
				</el-form-item>
				<el-form-item>
				  <el-button style="width: 100%;height: 47px;" type="primary" @click="submitForm">
					注册
				  </el-button>
				</el-form-item>
				<el-form-item>
					<el-link type="info" href="/account/login">登录</el-link>
				</el-form-item>
			  </el-form>
		  </div>
	</div>
</template>

<script setup>
	import { reactive, ref } from 'vue'
	import { ElMessage, ElMessageBox } from 'element-plus'
	import { useRouter } from 'vue-router'
	import { register } from '@/api/user'
	
	let router = useRouter();
	
	const labelPosition = ref('top')
	const comfirmPassword = ref('')
	const form = reactive({
	  userName: '',
	  nickName: '',
	  email: '',
	  sex: '',
	  password: ''
	})
	const submitForm = () => {
		if(comfirmPassword.value != form.password){
			ElMessage({
			    message: 'Passwords does not match',
			    type: 'error',
			})
			return false
		}
		if(!form.userName){
			ElMessage({
			    message: 'Cannot be empty',
			    type: 'error',
			})
			return false
		}
		if(!form.email){
			ElMessage({
			    message: 'Cannot be empty',
			    type: 'error',
			})
			return false
		}
		if(!form.password){
			ElMessage({
			    message: 'Cannot be empty',
			    type: 'error',
			})
			return false
		}
		register(form).then((res) => {
			ElMessage({
			    message: 'Success',
			    type: 'success',
			})
			router.push('/account/login')
		})
	}
	const resetForm = () => {
		
	}
</script>
<style lang="less">
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>
	/deep/ .el-radio-group{
		.el-radio-button{
			width: 50%;
			.el-radio-button__inner{
				width: 100%;height: 45px;line-height: 20px;
			}
		}
	}
	.maxBox{flex-wrap: wrap;}
	h1{width: 100%;}
	.loginBox{
		max-width: 446px;margin: 0 auto;width: 100%;
	}
</style>
