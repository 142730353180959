<template>
	<el-drawer v-if="isMobile()"
	    v-model="visible"
	    direction="btt"
	    size="78%"
	    class="bttDrawer"
		:append-to-body="true"
	    :before-close="cancel"
	  >
	  <template #header>
		<h4 class="titleBox">{{ title }}</h4>
	  </template>
	  <slot></slot>
	</el-drawer>
	<el-dialog v-else v-model="visible" :title="title" :width="width" @close="cancel">
		<slot></slot>
	</el-dialog>
</template>

<script setup>
	import { getCurrentInstance, reactive, ref, onMounted, defineProps, defineEmits, computed } from 'vue'
	import { isMobile } from '@/utils/common.js'
	
	const { proxy } = getCurrentInstance();
	const props = defineProps({
		showVisible: {
			type: Boolean,
			default: false
		},
		showTitle: {
			type: String,
			default: ''
		},
		showWidth: {
			type: Number,
			default: 500
		}
	})
	const emits = defineEmits(['cancel'])
	const cancel = () => {
		emits('cancel')
	}
	const visible = computed(() => {
		return props.showVisible
	})
	const title = computed(() => {
		return props.showTitle
	})
	const width = computed(() => {
		return props.showWidth
	})
</script>

<style>
</style>