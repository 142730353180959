import request from "@/utils/request";

// // 购物车支付
// export function paypal(query) {
//   return request({
//     url: '/shopping/payPal',
//     headers: {
//       isToken: true
//     },
//     method: 'post',
// 	data: query
//   })
// }
// 创建订单
export function payorder(query) {
  return request({
    url: '/orderInfo/createH5Order',
    headers: {
      isToken: true
    },
    method: 'post',
	data: query
  })
}
// 支付宝支付(ostype:99是表单，不传默认二维码)
export function payAlimoney(query) {
  return request({
    url: '/orderInfo/aliPayInfo',
    headers: {
      isToken: true
    },
    method: 'post',
	data: query
  })
}
// 微信支付
export function payWxmoney(query) {
  return request({
    url: '/orderInfo/weChatPayInfo',
    headers: {
      isToken: true
    },
    method: 'post',
	data: query
  })
}
// 订单状态查询
export function payStatus(query) {
  return request({
    url: '/orderInfo/payStatus',
    headers: {
      isToken: true
    },
    method: 'get',
	params: query
  })
}
// 微信公众号appid查询
export function getWxOpenId(query) {
  return request({
    url: '/gameInfo/getOpenId',
    method: 'get',
	params: query
  })
}