<template>
	<section class="bg1" v-if="game">
		<div class="detailBox" :class="{'pc':!isMobile(),'m':isMobile()}">
			<div class="imgBox">
				<ul>
					<li>
						<img v-if="game.gameFrontCover" :src="game.gameFrontCover" />
					</li>
				</ul>
			</div>
			
		</div>
	</section>
	
	<section class="bg1" v-if="game">
		  <div class="gameInfo" :class="{'pc':!isMobile(),'m':isMobile()}">
			  <div class="noUserBox" v-if="!userToken">
				  <div style="margin: 0 auto;">
					  <div class="gameIcon">
						  <img v-if="game.gameIcon" :alt="game.gameKrName" :src="game.gameIcon" />
						  <p>{{ game.gameKrName }}</p>
					  </div>
					  <div class="btns">
						  <el-button type="primary" @click="openLogin = true">登录</el-button>
						  <p>充值前请先登录奇葩葩账号</p>
					  </div>
				  </div>
			  </div>
			  <div class="isUserBox" v-else>
				  <div class="accountBox" v-if="!isMobile()">
					  <h4>账号详情</h4>
					  <div class="userInfo">
						  <el-descriptions :column="isMobile()?1:2">
						      <el-descriptions-item label="账号：">{{ formatName(userName) }}
								<el-button style="margin-left: 20px;" key="plain" type="primary" link :icon="Switch" @click="showSubUser">
							        绑定角色
							      </el-button>
							  </el-descriptions-item>
							  <template v-if="userGame && userGame.id">
								  <el-descriptions-item label="角色昵称：">{{ userGame.roleName }}</el-descriptions-item>
								  <el-descriptions-item label="区服：">
									<el-tag size="small">{{ userGame.serviceName }}</el-tag>
								  </el-descriptions-item>
								  <!-- <el-descriptions-item label="UID：">{{ userGame.superUserId }}</el-descriptions-item> -->
								  <el-descriptions-item label="可用积分：">{{ points }}</el-descriptions-item>
							  </template>
						</el-descriptions>
						<p class="emptyTips" v-if="!userGame.id">
							<el-icon :size="16" style="margin-right: 5px;"><WarningFilled /></el-icon>
							请先绑定游戏角色信息！
						</p>
					  </div>
				  </div>
				  <div class="accountBox" v-else>
					  <div class="userInfo">
						  <span class="ctips">（代金券可换区使用）</span>
						  <div class="infos">
							  <span style="display: inline-flex;align-items: center;">
								{{ formatName(userName) }}
								<el-button style="margin-left: 8px;font-size:12px;" key="plain" type="primary" link :icon="Switch" @click="showSubUser">
							      绑定角色
							    </el-button>
							  </span>
							  <p style="display: inline-flex;align-items: center;">
								  <span class="coins" style="margin-right: 10px;"><em>代金券：</em>{{ coupons }}</span>
								  <span class="coins"><em>积分：</em>{{ points }}</span>
							  </p>
						  </div>
						  <div class="infos">
							  <template v-if="userGame && userGame.id">
								  <span class="roles">{{ userGame.serviceName + ' ' + userGame.roleName }}</span>
								  <div class="btns">
									  <router-link :to="{name:'exList',params:{gid:pGame}}" class="btn">代金券明细</router-link>
									  <router-link to="/record/list" class="btn">积分明细</router-link>
								  </div>
							  </template>
							  <span class="tips" v-if="!userGame.id">
								  <el-icon :size="13" style="margin-right: 5px;"><WarningFilled /></el-icon>
								请先绑定游戏角色信息！
								</span>
							  
						  </div>
					  </div>
				  </div>
				  <div class="goodsBox" v-if="goodList.length > 0">
					<el-tabs v-model="activeName" class="tabs" @tab-click="handleClick">
				      <el-tab-pane label="选择商品" name="money">
						  <div class="good1List" :class="{'pc':!isMobile(),'m':isMobile()}">
							  <ul>
								  <template v-for="(item,index) in goodList" :key="index">
									<li class="item" :class="item.id == activeGood.id ? 'active' : ''" @click="handelGoodItem(item)">
										<span class="discountTag" v-if="item.type == 1 || item.type == 2">{{ (item.discount*10).toFixed(countDecimals(item.discount)) }}折</span>
										<div class="desBox">
											<h3 class="title">
												{{item.goodName}}
											</h3>
										</div>
									</li>
								  </template>
							  </ul>
							  <el-affix target=".good1List" position="bottom" :offset="0">
							        <div class="totalPay" v-if="activeGood.id">
										  <div class="paymoney">
											  合计：<i class="unit">¥</i><span class="real">{{ activeGood.realPrice }}</span>
												<i class="oprice">（ ¥ {{ activeGood.goodPrice }} )</i>
										  </div>
										  <div class="paybtn" @click="handlePay()">立即支付</div>
							        </div>
							  </el-affix>
						  </div>
						  
						  
					  </el-tab-pane>
				      <el-tab-pane label="积分兑换" name="coin">
						  <div class="goodList" :class="{'pc':!isMobile(),'m':isMobile()}">
							  <ul v-if="giftList.length > 0">
								  <template v-for="(item,index) in giftList" :key="index">
									<li class="item" v-loading="payLoading" @click="handleExchange(item)">
										<span class="firstTag" v-if="!isMobile() && formatCycle(item.cycle,item.num)">{{ formatCycle(item.cycle,item.num) }}</span>
										<div class="imgBox">
											<img :src="item.props.icon" />
										</div>
										<div class="desBox">
											<h3 class="title1">
												{{item.props.name}} * {{item.props.amount}}
											</h3>
											<p class="price">
												<span class="blue">{{ item.money }}<span class="small">积分</span></span>
												<span class="small red" v-if="isMobile()">{{ formatCycle(item.cycle,item.num) }}</span>
											</p>
										</div>
									</li>
								  </template>
							  </ul>
							  <p style="font-size: 14px;color: #999;text-align: center;margin: 30px 0;" v-else>暂未开放，敬请期待</p>
						  </div>
					  </el-tab-pane>
				    </el-tabs>
					  <!-- <h4>选择商品</h4> -->
					  
				  </div>
			  </div>
		  </div>
	</section>
	<template v-if="isMobile()">
		<h5Pay v-if="openPay && userToken" :showPay="openPay" :gameid="pGame" :good="activeGood" @cancel="cancelPay" />
	</template>
	<template v-else>
		<Pay v-if="openPay && userToken" :showPay="openPay" :gameid="pGame" :good="activeGood" @cancel="cancelPay" />
	</template>
	
	<SubAccount v-if="openSubUser && userToken" :showSubList="openSubUser" :gameid="pGame" @changeSubUser="changeSubUser" />
	<Login v-if="game && openLogin" :showLogin="openLogin" :gameid="pGame" @loginSuccess="loginSuccess" />
	<Exchange v-if="openExchange && userToken" :showPay="openExchange" :games="game" :gameid="pGame" :good="activeGift" @cancel="cancelExchange" />
</template>

<script setup>
	import { ElMessage, ElMessageBox } from 'element-plus'
	import { ref, onMounted, onUnmounted, computed, watch, inject, reactive } from "vue";
	import { useRouter,useRoute } from 'vue-router'
	import { getGameSearch, getGoodList } from '@/api/index'
	import { payorder } from '@/api/pay'
	import { pointGiftList, getUserInfo, couponInfo } from '@/api/point'
	import { isMobile,iswxBrowser,wxcode } from '@/utils/common.js'
	import useUserStore from '@/store/modules/user'
	import SubAccount from '@/components/subAccount'
	import Login from '@/components/login'
	import Pay from '@/components/pay'
	import Exchange from '@/components/exchange'
	
	import h5Pay from '@/components/h5pay'
	import { Switch } from '@element-plus/icons-vue'
	
	let router = useRouter();
	let route = useRoute();
	const reload = inject('reload');
	const openLogin = ref(false)
	const openSubUser = ref(false)
	const openPay = ref(false)
	const openExchange = ref(false)
	const pGame = ref(0)
	const game = ref({})
	// const user = ref({})
	const userGame = ref({})
	const userToken = ref('')
	const userName = ref('')
	const userInfo = ref({})
	const isLogin = ref(false)
	const giftList = ref([])
	const goodList = ref([])
	const activeGood = ref({})
	const activeGift = ref({})
	const activeOpenid = ref('')
	const user = computed(() => {
		return useUserStore().userInfo || {}
	})
	const openids = computed(() => {
		return useUserStore().getOpenIds() || ''
	})
	const userSign = computed(() => {
		return useUserStore().token || ''
	})
	const roleInfos = computed(() => {
		return useUserStore().roleInfo || {}
	})
	const points = computed(() => {
		return useUserStore().points || 0
	})
	const coupons = computed(() => {
		return useUserStore().coupons || 0
	})
	const payLoading = ref(false)
	const activeName = ref('money')
	onMounted(()=>{
		pGame.value = Number(route.params.pid)
		useUserStore().setActiveGame(pGame.value)
		getGameSearch({ id: route.params.Id }).then((res)=>{
			game.value = res.data
		})
		if(useUserStore().name){
			userName.value = useUserStore().name
		}
		if(userGame.value.id){
			initRole(pGame.value)
			getGoods()
			getUsers()
		}
		if(iswxBrowser()){
			window.addEventListener('visibilitychange',handleVisible)
		}
		if(isMobile() && iswxBrowser()){
			if(!hasOpenId()){
				wxcode(pGame.value)
			}
		}
	})
	onUnmounted(() => {
		if(iswxBrowser()){
			window.removeEventListener('visibilitychange',handleVisible)
		}
	})
	const countDecimals = (num) => {
	    // 将数字转换为字符串
	    let numStr = num.toString();
	    
	    // 判断是否包含小数点
	    if (!numStr.includes('.')) {
	        return 0; // 如果没有小数点，则返回0
	    }
	    
	    // 使用split方法以小数点分割
	    let [integerPart, decimalPart] = numStr.split('.');
	    
	    // 返回小数部分的长度
	    return decimalPart.length-1;
	}
	const hasOpenId = () => {
		let obj = openids.value.find((item) => item.gameId == pGame.value)
		return obj
	}
	const handleVisible = () => {
		location.reload()
	}
	const getUsers = () => {
		getUserInfo().then((res) => {
			useUserStore().setPoints(res.data.points)
		})
		couponInfo({ userId: useUserStore().userid, gameId: pGame.value }).then((res) => {
			useUserStore().setCoupons(res.data.coupon)
		})
	}
	const formatName = (val) => {
		if(val && val.length == 11){
			return val.replace(/(\d{3})\d{5}(\d{3})/, '$1*****$2')
		}else{
			return val
		}
	}
	const formatCycle = (val,num) => {
		let str = ''//兑换周期1天2周3月99永久
		if(val == 1){
			str = `每天${num}次`
		}else if(val == 2){
			str = `每周${num}次`
		}else if(val == 3){
			str = `每月${num}次`
		}
		return str
	}
	const cancelExchange = () => {
		openExchange.value = false
		getUsers()
	}
	const cancelPay = () => {
		openPay.value = false
		payLoading.value = false
		getUsers()
	}
	const loginSuccess = (obj) => {
		if(obj.accessToken){
			userToken.value = obj.accessToken
			userName.value = obj.userName
			userInfo.value = Object.assign({},obj)
			// location.reload()
			getUsers()
		}
		openLogin.value = false
	}
	
	const changeSubUser = (obj) => {
		// openSubUser.value = false
		// userGame.value = Object.assign({},obj)
		console.log(obj)
		if(obj.id){
			userGame.value = Object.assign({},obj)
			getGoods()
			
		}
		openSubUser.value = false
	}
	const showSubUser = () => {
		openSubUser.value = true
	}
	
	const handleClick = ({ paneName }) => {
		console.log(paneName)
		if(paneName == 'coin'){
			getGifts()
		}else{
			getGoods()
		}
	}
	
	const getGifts = () => {
		pointGiftList({ gameId: pGame.value, roleId: userGame.value.roleId }).then((res) => {
			giftList.value = res.data.map((item) => {
				if(item.items){
					item.props = JSON.parse(item.items)[0]
				}else{
					item.props = {}
				}
				return item
			})
			console.log(giftList.value)
		})
	}
	const getGoods = () => {
		getGoodList({ gameId: pGame.value }).then((res) => {
			console.log(res)
			goodList.value = res.data
			if(goodList.value.length > 0){
				activeGood.value = goodList.value[0]
			}
		})
	}
	const handelGoodItem = (row) => {
		activeGood.value = Object.assign({},row)
	}
	const handleExchange = (row) => {
		if(row.residueNum == 0){
			ElMessage({
			    message: '暂无可兑换次数～',
			    type: 'error',
			})
			return false
		}
		activeGift.value = { roleName: userGame.value.roleName, ...row }
		console.log(activeGift.value)
		openExchange.value = true
	}
	// 创建订单
	const handlePay = () => {
		if(!userGame.value.sonUserId){
			ElMessage({
			    message: '请先选择小号角色',
			    type: 'error',
			})
			return false
		}
		if(!hasOpenId() && iswxBrowser()){
			wxcode(pGame.value)
			return false
		}
		payLoading.value = true
		payorder({
			sonUserId: userGame.value.sonUserId,//  小号用户ID
			roleId:userGame.value.cpRoleId,// 研发角色id(roleId是发行的)
			roleName:userGame.value.roleName,// 
			superUserId:userGame.value.superUserId,// 
			roleLevel:userGame.value.roleLevel,// 
			gameId:userGame.value.gameId,// 自有平台游戏ID
			serviceId:userGame.value.serviceId,// 
			serviceName:userGame.value.serviceName,// 
			goodId:activeGood.value.goodNo,// 
			goodName:activeGood.value.goodName,// 
			discount:0,//   优惠折扣
			amountShould:activeGood.value.goodPrice,//    应付金额
			amountReal:activeGood.value.realPrice,//   实付金额
			amountReduce :0//  减免金额
		}).then((res) => {
			activeGood.value = Object.assign(activeGood.value,{ orderId: res.data, roleName: userGame.value.roleName })
			openPay.value = true
			console.log(res)
		}).catch(() => {
			payLoading.value = false
		})
		
	}
	const initRole = (gameid) => {
		if(useUserStore().roleInfo.length > 0){
			let obj = useUserStore().roleInfo.find((item) => item.gameId == gameid)
			if(obj){
				userGame.value = Object.assign({},obj)
			}else{
				userGame.value = {}
			}
		}
		
	}
	
	watch(userSign, (newVal, oldVal) => {
		if(newVal){
			openLogin.value = false
			userToken.value = newVal
			getUsers()
		}else{
			openLogin.value = true
			userToken.value = ''
		}
	},{ immediate: true })
	watch(roleInfos, (newVal, oldVal) => {
		if(newVal && useUserStore().gameid){
			initRole(useUserStore().gameid)
		}
	},{ immediate: true, deep: true })
	watch(user, (newVal, oldVal) => {
		if(newVal){
			// userToken.value = newVal.accessToken
			userName.value = newVal.userName
			userInfo.value = Object.assign({},newVal)
		}
	},{ immediate: true, deep: true })
</script>
<style lang="less">
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>
	.downloadBtn{
		width: 100%;height: 4.5rem;line-height: 4.5rem;text-align: center;max-width: 18.75rem;
		margin: 0 auto 4rem;background: #000;cursor: pointer;
		a{
			display: inline-block;color: #fff!important;
			width: 100%;height: 100%;font-size: 1.25rem;
		}
	}
	.gameInfo{
		width: 100%;display: flex;max-width: 120rem;justify-content: center;
		margin: 0 auto;padding-bottom: 48px;box-sizing: border-box;
		
		.noUserBox{
			width: 100%;height: 100%;background: #fff;border-radius: 20px;
			height: 320px;display: flex;align-items: center;flex-wrap: wrap;
			.gameIcon{
				text-align: center;width: 100%;
				img{
					width: 92px;height: 92px;margin-bottom: 8px;
				}
				p{
					font-size: 20px;line-height: 28px;
				}
			}
			.btns{
				width: 100%;text-align: center;margin-top: 24px;
				.el-button{
					width: 258px;height: 48px;border-radius: 12px;font-size: 20px;
				}
				p{
					color: #98999e;line-height: 24px;margin-top: 16px;font-size: 16px;
				}
			}
		}
		.isUserBox{
			width: 100%;height: 100%;background: #fff;border-radius: 20px;
			display: flex;align-items: center;flex-wrap: wrap;
			h4{
				position: relative;display: block;
				    height: 25px;
				    line-height: 25px;
				    font-weight: 600;
				    font-size: 18px;
				    margin-bottom: 16px;color: #000;padding-left: 13px;
					&::before{
						margin-right: 10px;position: absolute;top: 3px;left: 0;
						    content: "";
						    width: 3px;
						    height: 20px;
						    background: #00c3ff;
						    border-radius: 2px;
							display: inline-block;
					}
			}
			.accountBox{
				width: 100%;padding: 32px;box-sizing: border-box;
				.userInfo{
					width: 100%;
					background: #f9fafc;border-radius: 12px;padding: 24px;box-sizing: border-box;
					.emptyTips{
						color: #98999e;font-size: 14px;
						display: inline-flex;align-items: center;
					}
				}
			}
			.goodsBox{
				width: 100%;padding: 0 32px 32px;box-sizing: border-box;
				.goodList,.good1List{
					&.pc{
						ul{
							li{
								.price{
									font-size: 18px;
								}
							}
						}
					}
					ul{
						padding: 10px 0 0;margin: 0;
						display: flex;align-items: flex-start;justify-content: flex-start;
						flex-wrap: wrap;
						li.item{
							width: calc(25% - 16px);margin-bottom: 20px;border-radius: 12px;background: #fff;position: relative;
							margin-right: 20px;border: 1px solid #eee;box-sizing: border-box;padding-top: 20px;
							&:nth-child(4n){
								margin-right: 0;
							}
							.discountTag{
								position: absolute;right: -1px;top: -1px;
								display: inline-block;padding: 3px 10px;background: orange;color: #fff;font-size: 12px;border-radius: 0 12px;
							}
							.firstTag{
								position: absolute;left: 10px;top: 10px;
								display: inline-block;padding: 3px 10px;background: orange;color: #fff;font-size: 12px;border-radius: 12px;
							}
							.imgBox{
								width: 92px;height: 92px;position: relative;font-size: 0;background: #ccc;font-size: 0;
								border-radius: 12px;overflow: hidden;margin: 20px auto;
								img{
									width: 100%;
								}
							}
							.desBox{
								padding: 0 0 2rem;
								.title,.title1{
									font-size: 1.6rem;font-weight: 400;letter-spacing: 0.06rem;margin: 0;
									text-align: center;cursor: pointer;
								}
								.price{
									line-height: 24px;margin-top: 10px;text-align: center;
									.onsale{
										color: rgb(18,18,18);display: inline-flex;width: 100%;
										font-size: 14px;align-items: center;justify-content: center;
										margin-top: 10px;color: #999;
										text-decoration: line-through;
									}
									.unit{
										display: inline-block;margin-right: 5px;font-style: normal;font-size: 1.2rem;
									}
									.el-button{
										width: 60%;height: 40px;border-radius: 12px;font-size: 1.6rem;letter-spacing: .06rem;
									}
									.small{
										font-size: 1rem;
									}
									.blue{
										color: #00c3ff;
									}
									.red{
										color: red;
									}
								}
							}
						}
					}
					&.m{
						ul{
							padding-top: 0;
							li.item{
								width: calc(50% - 8px);margin-right: 16px;border-radius: 10px;margin-bottom: 16px;padding-top: 0;
								.imgBox{
									// width: 56px;height: 56px;margin: 10px auto;
									width: 100%;padding-top: 100%;position: relative;height: auto;margin: 0;border-radius: 10px 10px 0 0;
									background: #F3F4F6;
									img{
										width: 72px;height: 72px;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);
									}
								}
								.discountTag{
									display: inline-block;padding: 1px 8px;background: orange;color: #fff;font-size: 10px;border-radius: 0 5px;
								}
								.desBox{
									padding-bottom: 0.8rem;
									.title1{
										text-align: left;padding: 0 10px;letter-spacing: normal;font-size: 1.375rem;font-weight: normal;margin: 0;
										margin-top: 0.8rem;
									}
									.price{
										margin-top: 0;display: inline-flex;align-items: center;justify-content: space-between;
										padding: 0 10px;box-sizing: border-box;width: 100%;font-size: 1.375rem;
										
										.onsale{
											margin-top: 0;
										}
										.el-button{
											font-weight: 600;
											width: 80%;height: 36px;border-radius: 10px;font-size: 1.6rem;letter-spacing: 0;
											
										}
									}
								}
								&:nth-child(2n){
									margin-right: 0;
								}
							}
						}
					}
				}
				.good1List{
					padding-bottom: 10px;
					.totalPay{
						background: #fff;padding: 15px 12px;box-shadow: 0 0 10px rgba(42,42,42,.06);box-sizing: border-box;
						width: 100%;height: 58px;font-size: 14px;border-radius: 30px;margin-top: 10px;
						display: flex;align-items: center;justify-content: space-between;
						.paybtn{
							width: 120px;height: 32px;line-height: 32px;text-align: center;
							background: #00c3ff;color: #fff;border-radius: 16px;
						}
						.paymoney{
							.real{
								font-size: 16px;
							}
						}
						.unit{
							display: inline-block;margin-right: 5px;font-style: normal;font-size: 1.2rem;
						}
						.oprice{
							font-size: 12px;text-decoration: line-through;color: #999;margin-left: 5px;font-weight: normal;
						}
					}
					ul{
						li{
							padding-top: 0;
							&.active{
								background: #00c3ff;color: #fff;border: 1px solid #00c3ff;
							}
						}
					}
					&.m{
						ul{
							li{
								padding-top: 0;cursor: pointer;
								&.item{
									width: calc(50% - 6px);margin-right: 12px;border-radius: 5px;margin-bottom: 12px;
								}
								.desBox{
									padding-top: 16px;padding-bottom: 16px!important;
								}
							}
						}
					}
				}
			}
		}
		
		&.m{
			// padding-left: 16px;padding-right: 16px;
			padding: 0;
			.isUserBox{
				background: linear-gradient(to bottom,transparent 0%,#fff 5%);border-radius: 0;
				.accountBox{
					padding: 0 0 10px;
					.userInfo{
						background: #ecf5ff;padding: 12px 14px 18px;border-radius: 0;position: relative;
						.ctips{
							position: absolute;bottom: 0;right: 10px;color: red;
						}
						.infos{
							font-size: 14px;
							display: flex;align-items: center;justify-content: space-between;
							.roles{
								font-size: 12px;
							}
							.tips{
								display: inline-flex;align-items: center;font-size: 12px;color: #999;
							}
							.coins{
								font-size: 12px;color: #999;
								em{
									font-style: normal;font-size: 10px;
								}
							}
							.btns{
								width: 145px;
								display: flex;align-items: center;justify-content: space-between;
								.btn{
									display: inline-block;padding: 2px 0;width: 68px;text-align: center;
									background: #fff;font-size: 12px;border-radius: 10px;
									color: #00c3ff;
								}
							}
						}
						.infos + .infos{
							margin-top: 5px;
						}
					}
				}
				.goodsBox{
					padding: 0 16px 0;
					
				}
			}
			.noUserBox{
				.btns{
					.el-button{
						height: 40px;border-radius: 10px;font-size: 16px;
					}
					p{
						font-size: 14px;margin-top: 12px;
					}
				}
			}
		}
		
	}
.detailBox{
	display: flex;max-width: 120rem;justify-content: center;
	margin: 0 auto;
	padding-top: 24px;padding-bottom: 24px;
	&.m{
		padding-left: 16px;padding-right: 16px;
		padding-top: 24px;padding-bottom: 24px;
	}
	.imgBox{
		width: 100%;border-radius: 20px;overflow: hidden;max-height: 255px;
		ul{
			display: flex;
			flex-wrap: wrap;
			margin: 0;
			padding: 0;
			grid-gap: 8px;
			li{
				width: calc(50% - 4px);font-size: 0;
				&:first-child{
					width: 100%;
				}
				img{
					width: 100%;
				}
			}
		}
	}
	
}

</style>
