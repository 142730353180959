import { createRouter, createWebHistory } from 'vue-router'
import Layout from '@/layout'
import { pcRoutes } from './pc.js'
// import { mRoutes } from './m.js'
import { isMobile } from '@/utils/common.js'

const currentRoutes = isMobile() ? pcRoutes : pcRoutes;
const routes = [
  {
    path: '',
    component: Layout,
    redirect: '/index',
    children: currentRoutes
  },
  // {
  //   path: '/order-info',
  //   component: () => import('@/views/record/order.vue'),
  //   hidden: true
  // },
  // {
  //   path: '/order-result',
  //   component: () => import('@/views/record/result.vue'),
  //   hidden: true
  // },
  {
  	  path: '/payali/:id/:platid?',
  	  component: () => import('@/views/pay/payali.vue'),
  	  name: 'Payali',
  	  meta: { title: '支付' },
  },
  {
  	  path: '/payresult/:id/:orderNo/:platid?',
  	  component: () => import('@/views/pay/payresult.vue'),
  	  name: 'Payresult',
  	  meta: { title: '支付' },
  },
  {
  	  path: '/wxcode',
  	  component: () => import('@/views/pay/wxcode.vue'),
  	  name: 'Wxcode',
  	  meta: { title: '支付' },
  },
  {
  	  path: '/iaasdk',
  	  component: () => import('@/views/iaa/adpopup.vue'),
  	  name: 'Iaasdk',
  	  meta: { title: '广告福利' },
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import('@/views/error/404.vue'),
    hidden: true
  },
  {
    path: '/401',
    component: () => import('@/views/error/401.vue'),
    hidden: true
  },
]

const router = createRouter({
	mode:'history',
	routes,
	history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

export default router
