<template>
	<div style="width: 100vw;height: 100vw;"></div>
</template>

<script setup>
	import { getCurrentInstance,onMounted,onBeforeMount,ref,watch,computed, reactive } from 'vue'
	import { getWxOpenid } from '@/api/user'
	import { useRoute,useRouter } from 'vue-router';
	import useUserStore from '@/store/modules/user'
	const router = useRouter();
	const query = ref({})
	onMounted(() => {
		query.value = router.currentRoute.value.query
		getWxOpenid({ code:query.value.code, gameId: query.value.gameId  }).then((res) => {
			if(res.data){
				useUserStore().setOpenId(res.data, query.value.gameId).then(() => {
					router.back();
				})
			}
		})
	})
</script>

<style>
</style>