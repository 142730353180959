<template>
	<Mydialog :showVisible="showPays" :showTitle="`确认兑换信息`" @cancel="cancel">
	<!-- <el-drawer
	    v-model="showPays"
	    direction="btt"
	    size="78%"
	    class="bttDrawer"
		:append-to-body="true"
	    :before-close="cancel"
	  >
	  <template #header>
		<h4 class="titleBox">确认购买信息</h4>
	  </template> -->
	    <div class="orderInfo" v-loading="imgLoading">
			<!-- <p class="goodPrice">{{ good.money }}<i class="unit">积分</i></p> -->
			<div class="lineItems">
				<img class="goodIcon" :src="good.props.icon" />
				<div>
					<p class="goodName">{{ good.props.name }} * {{ good.props.amount }}</p>
					<p class="freeCoin"><i class="tag">兑</i>{{ good.money }}积分</p>
				</div>
			</div>
			<div class="lineItems">
				<span class="labelTxt">充值角色</span>
				<span class="valTxt">{{ good.roleName }}</span>
			</div>
			<div class="lineItems">
				<span class="labelTxt">兑换物品数量</span>
				<span class="valTxt">
					<i class="minus" @click="handleMinus(good)"><el-icon><Minus /></el-icon></i>
					<i class="nums">{{ activeNum }}</i>
					<i class="plus" @click="handlePlus(good)"><el-icon><Plus /></el-icon></i>
				</span>
			</div>
			<div class="lineItems">
				<span class="labelTxt">当前可用积分</span>
				<span class="valTxt">{{ points }}</span>
			</div>
			<div class="lineItems">
				<span class="labelTxt">本次扣除</span>
				<span class="valRedTxt">{{ (good.money*activeNum).toFixed(0) }}</span>
			</div>
			<a class="payBtn" @click="handleExchange" >确认兑换</a>
			
		</div>
	<!-- </el-drawer> -->
	</Mydialog>
</template>

<script setup>
	import Mydialog from '@/components/mydialog'
	import { ElMessage, ElMessageBox } from 'element-plus'
	import { getCurrentInstance, reactive, ref, onMounted, defineProps, defineEmits, computed } from 'vue'
	import { exchangePoint } from '@/api/point'
	import { useRoute,useRouter } from 'vue-router';
	import { isMobile } from '@/utils/common.js'
	import useUserStore from '@/store/modules/user'
	import variables from '@/assets/css/variables.less'
	const variablesStyles = ref(variables)
	const { proxy } = getCurrentInstance();
	const router = useRouter();
	const props = defineProps({
		good: {
			type: Object
		},
		showPay: {
			type: Boolean,
			default: false
		},
		games: {
			type: Object
		},
		gameid: {
			type: Number,
			default: 0
		}
	})
	const emits = defineEmits(['cancel'])
	const cancel = () => {
		emits('cancel')
	}
	
	const showPays = computed(() => {
		return props.showPay
	})
	const gameInfos = computed(() => {
		return props.games
	})
	const gameId = computed(() => {
		return props.gameid
	})
	const goods = computed(() => {
		console.log(goods)
		return props.good
	})
	const role = computed(() => {
		return useUserStore().roleInfo
	})
	const points = computed(() => {
		return useUserStore().points || 0
	})
	const imgLoading = ref(false)
	const activeNum = ref(1)
	onMounted(() => {
		
	})
	
	const showPaySuccess = () => {
		ElMessageBox.confirm(
		    '请前往游戏内查收商品',
		    '兑换成功',
		    {
		      confirmButtonText: '好的',
			  showCancelButton: false,
		      type: 'success',
		    }
		  )
		    .then(() => {
				cancel()
		    })
			.catch(() => {
				cancel()
			})
		    
	}
	const handleMinus = (row) => {
		console.log(row)
		if(activeNum.value > 1){
			activeNum.value --
		}else{
			ElMessage({
			    message: `至少需要兑换${activeNum.value}个`,
			    type: 'error',
			})
		}
	}
	const handlePlus = (row) => {
		if(activeNum.value < row.residueNum){
			activeNum.value ++
		}else{
			ElMessage({
			    message: `最多只能兑换${row.residueNum}个`,
			    type: 'error',
			})
		}
		console.log(row)
	}
	const handleExchange = () => {
		let roles = role.value.find((item) => item.gameId == gameId.value)
		let args = {
			userId: roles.userId,//自有平台用户ID
			sonId: roles.sonUserId,//子用户ID
			superUserId: roles.superUserId,//发行用户ID
			goodId: goods.value.id,//
			gameId: gameInfos.value.gameId,//sdk游戏ID
			myGameId: gameId.value,//自有平台游戏ID
			goodName: goods.value.title,//
			serviceId: roles.serviceId,//
			serviceName: roles.serviceName,//
			roleName: roles.roleName,//
			roleId: roles.cpRoleId,//研发角色ID
			superRoleId: roles.roleId//发行角色ID
		}
		exchangePoint({ num: activeNum.value, ...args }).then((res) => {
			console.log(res)
			showPaySuccess()
		})
	}
	
	
</script>

<style lang="less">
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>
	.payBtn{
		display: inline-block;color:#fff;background: #00c3ff;
		width: 100%;line-height: 36px;text-align: center;margin-left: 0;
		border-radius: 10px;margin-top: 60px;
	}
	.normalBtn{
		display: inline-block;background: #fff;
		width: 100%;line-height: 36px;text-align: center;margin-left: 0;
		border-radius: 10px;margin-top: 60px;color: #999;
	}

	.orderInfo{
		font-size: 14px;
		.lineItems{
			width: 100%;margin: 8px auto 0;background: #fff;box-sizing: border-box;
			border-radius: 10px;padding: 14px 16px;
			display: inline-flex;align-items: center;justify-content: space-between;
			.tag{
				display: inline-block;width: 18px;height: 18px;font-size: 12px;
				background: #FF5340;color: #fff;font-style: normal;line-height: 18px;text-align: center;
				border-radius: 2px;margin-right: 3px;
			}
			.freeCoin{
				color: #999;display: flex;align-items: center;margin-top: 3px;justify-content: flex-end;
			}
			.goodIcon{
				width: 46px;height: 46px;border-radius: 12px;
			}
			.goodName{
				color: #0c0c0c;font-size: 16px;
			}
			.labelTxt{ color: #666;}
			.valTxt{ 
				color: #0c0c0c;
				display: inline-flex;align-items: center;
			}
			.valRedTxt{ color: #FF5340;}
			.minus{ margin-right: 5px; }
			.plus{ margin-left: 5px; }
			.minus,.plus,.nums{
				display: inline-block;background: #F3F2F6;width: 26px;height: 26px;text-align: center;
				padding: 5px;box-sizing: border-box;font-style: normal;
			}
		}
		.goodPrice{
			text-align: center;color: red;font-size: 30px;margin-bottom: 20px;font-weight: 600;
			.oprice{
				font-size: 12px;text-decoration: line-through;color: #999;margin-left: 5px;font-weight: normal;
			}
			.unit{
				font-size: 16px;font-weight: normal;font-style: normal;margin-left: 5px;
			}
		}
		.goodTitle{
			text-align: center;margin: 10px 0;
		}
		.payTyps{
			display: flex;align-items: center;flex-wrap: wrap;
			width: 100%;margin: 8px auto 0;background: #fff;box-sizing: border-box;
			border-radius: 12px;padding: 8px 14px;
			.payLines{
				width: 100%;display: flex;align-items: center;justify-content: space-between;
				cursor: pointer;
				&:first-child{
					padding-bottom: 5px;
				}
				.emp{
					width: 15px;height: 15px;color:#fff;border-radius: 100%;display: inline-block;position: relative;
					border: 1px solid #ccc;box-sizing: border-box;
				}
			}
			.payLines + .payLines{
				border-top: 1px solid #f9fafc;padding-top: 5px;
			}
			.item{
				width: 42%;line-height: 36px;display: inline-flex;align-items: center;justify-content: left;
				font-size: 16px;
			}
		}
		
	}
</style>